import React, { useState }from 'react'
import { Spin } from "react-cssfx-loading";
import { Download, Paperclip } from "lucide-react";
import { Button } from "@/global-components/components/ui/button";

const DownloadButton = (props: {url: string, name: string, link?: boolean}) => {
  const [downloading, setDownloading] = useState<boolean>(false)

  const downloadFileFromUrlWithCustomName = async (
    fileUrl: string,
    fileName: string
  ) => {
    if (downloading) {
      console.log(`Download of '${fileName}' is already in progress.`);
      return;
    }

    try {
      setDownloading(true)

      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.setAttribute('download', fileName);
      downloadLink.target = '_blank';
      downloadLink.rel = 'noopener noreferrer';

      downloadLink.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setDownloading(false)
    }
  };

  if (props.link) {
    return (
      <Button 
        variant='link' 
        size='sm' 
        disabled={downloading} 
        className='text-xs opacity-30 flex gap-1 hover:opacity-100' 
        onClick={() => downloadFileFromUrlWithCustomName(props.url, props.name)}>
          {downloading ? 
            <Spin className="inline-spin h-4 w-4" color="#36463D" width="10px" height="10px" duration="0.3s" /> 
            : <Paperclip className='h-4 w-4'></Paperclip> 
          } {props.name}
      </Button>
    )
  } else {
    return (
      <Button 
        variant='bwsecondary' 
        size='smallicon' 
        disabled={downloading} 
        onClick={() => downloadFileFromUrlWithCustomName(props.url, props.name)}>
          {downloading ? 
            <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" /> 
            : <Download className='h-4 w-4'></Download>}
      </Button>
    )
  }
}

export default DownloadButton;