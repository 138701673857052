import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery, QueryResult } from '@apollo/client';

import { useAuth } from '../../context/auth';
import { PrintRequest, User, Version, FilamentType, FileType, ProductType, ProductStatus, ProductPreferences, ProductGroup} from '@/global-components/types'

import api from '../../api/bw-api';

import ProductDelete from './productDelete/ProductDelete';
import GroupSelect from './groupSelect/GroupSelect';
import VersionSelect from './versionSelect/VersionSelect';
import ProductDetails from './productDetails/ProductDetails';
import STLViewer from '../../components/STLViewer/STLViewer';

import ProductActions from '@/components/productRelated/ProductActions';
import GCodeViewer from '@/global-components/components/bw/gCodeViewer/GCodeViewer';
import { Pencil, Save, ChevronLeft, ChevronRight, ChevronDown, Info, XCircle, X, Check, Fullscreen, Minimize } from "lucide-react";

import helpers from "@/global-components/components/helpers"

import { useToast } from "@/global-components/components/ui/use-toast";

import './Product.scss';
import { Button } from '@/global-components/components/ui/button';
import OrganisationSelect from '@/global-components/components/bw/organisationSelect/OrganisationSelect';

const Product = (props: any) =>  {
  const { productRef } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);
  const version = urlQuery.get('v');
  const loggedin = useAuth().loggedIn;
  const { user } = useAuth()

  const [files, setFiles] = useState<FileType[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [gcode, setGcode] = useState<FileType | undefined>(undefined)

  const [forceReloadFiles, setForceReloadFiles] = useState<boolean>(false)

  const [viewerExpanded, expandViewer] = useState<boolean>(false)
  const [activePreview, setActivePreview] = useState<number>(0);
  const [product, setProduct] = useState<ProductType | null>(null);
  const [isSharedWithYou, setIsSharedWithYou] = useState<boolean>(false)

  const [uploading, setUploading] = useState(false);


  const productQuery: QueryResult = useQuery(api.products.queries.GET_PRODUCT_BY_REF, {
    variables: {reference: productRef, versionNumber: version},
    pollInterval: 300,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !productRef,
  });

  useEffect(() => {
    if (productQuery.error) {
      toast({
        title: "Loading Product Data",
        variant: 'destructive',
        description: "Error while fetching product data from Server. If this persists, please contact software@batch.works"
      })
    }
  }, [productQuery.error])

  const [updateProductWithFiles] = useMutation(api.products.mutations.UPDATE_PRODUCT_WITH_FILES);
  const [updateProduct, updateProductResult] = useMutation(api.products.mutations.UPDATE_PRODUCT);
  const [productStatusSeen] = useMutation(api.products.mutations.PRODUCT_STATUS_SEEN);
  const { toast } = useToast()

  const getCadFiles = (): FileType[] => {
    return product?.filemodelSet?.filter((filemodelSet: any) => 
              filemodelSet.presignedUrl.toLowerCase().includes('.stl') || filemodelSet.presignedUrl.toLowerCase().includes('.gcode')) ?? []
  }

  const showNextFile = () => {
    setActivePreview(prevActivePreview => {
      const nextIndex = prevActivePreview + 1;
      return nextIndex < getCadFiles().length ? nextIndex : 0;
    });
  };  

  const showPreviousFile = () => {
    setActivePreview(prevActivePreview => {
      const prevIndex = prevActivePreview - 1;
      return prevIndex >= 0 ? prevIndex : getCadFiles().length - 1;
    });
  };
  

  const handleKeyDown = (event: KeyboardEvent) => {
    const activeElement = document.activeElement;

    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      activeElement instanceof HTMLSelectElement
    ) {
      return
    }

    switch (event.key) {
        case 'ArrowRight': 
          showNextFile();
          break;
        case 'ArrowLeft': 
          showPreviousFile();
          break;
        case 'f' || 'F':
          expandViewer(expanded => !expanded)
          break;
        default:
          break;
    }
  };

  const updateProductStatusSeen = () => {
    if (!product?.productId) {
      return;
    }
    productStatusSeen({ variables: { productId: product?.productId }})
      .then((result: any) => {
        
      })
      .catch((error : any) => { 
      })
  }

  const updateProductStatus = (status: ProductStatus) => {
    setUploading(true);
     updateProduct({ variables: { productId: product?.productId, status: status }})
      .then((result: any) => {
        if (result.data.updateProduct.success) {
          productQuery.refetch();
          setUploading(false);
          toast({
            title: "Product status successfully updated to " + status,
            variant: "success",
            duration: 2000
          })
        } else {
          setUploading(false);
        }
      })
      .catch((error : any) => { 
        setUploading(false);
        
      })
  };


  const updateProductGroup = (groupId: string) => {
    updateProduct({ variables: { productId: product?.productId, groupId: groupId}})
      .then((result: any) => {
        if (result.data.updateProduct.success) {
          productQuery.refetch();
          toast({
            title: "Product group successfully updated",
            variant: "success",
            duration: 2000
          })
        }
      })
      .catch((error : any) => {})
  }

  const toggleOrganisationOnProduct = (id: string) => {
    updateProduct({variables: {productId: product?.productId, organisationId: id }})
    .then((result: any) => {
      if (result.data.updateProduct.success) {
        productQuery.refetch();
        toast({
          title: "Changed product's organisations successfully",
          variant: 'success'
        })
      }
    })
    .catch((error : any) => {
      toast({
        title: "Error changing product's orgs",
        description: error,
        variant: 'destructive'
      })
    })
  }

  const addFilesToProduct = () => {
    setUploading(true);
    updateProductWithFiles({ variables: { files: filesToUpload, productId: product?.productId }})
      .then((result: any) => {
        setFilesToUpload([]);
        if (result.data.updateProductWithFiles.success) {
          setUploading(false);
          toast({
            title: "File successfully added"
          })
          productQuery.refetch();
        } else {
          setUploading(false);
          // errors index and files index same index printable attribute will be false
          toast({
            title: "It Almost Worked",
            description: "Most likely the algorithm could not calculate the costs for the product. We have still added the GCode but not approved it, since we need to update the costs for it before it can be printed by the client.",
            duration: 10000,
          })
          productQuery.refetch();
        }
      })
      .catch((error : any) => {   
        setUploading(false);
        toast({
          title: "Error " + error.message,
          variant: 'destructive'
        })
      })  
  }

  const filesInProductChanged = (files: any[], previousFiles: any[]): boolean => {
    return forceReloadFiles ? true : !_.isEqual(
      files?.map((file: any) => file.fileId), 
      previousFiles?.map((file: any) => file.fileId)
    )
  }

  const refetchProduct = (reloadFiles: boolean) => {
    setForceReloadFiles(reloadFiles)
    productQuery.refetch()
  }

  useEffect(() => {
    if (product?.createdBy?.userId !== user?.userId) {
      setIsSharedWithYou(true)
    } else {
      setIsSharedWithYou(false)
    }
  }, [product, user])

  useEffect(() => {
    setProduct(productQuery?.data?.productByRef)
    if (filesInProductChanged(productQuery?.data?.productByRef.filemodelSet, productQuery?.previousData?.productByRef.filemodelSet)) {
      setFiles(product?.filemodelSet ?? [])
      setGcode(product?.filemodelSet?.find(file => file.fileName.endsWith('.gcode')))
      setForceReloadFiles(false)
    }

    if(!productQuery?.data?.productByRef.statusSeen && productQuery?.data?.productByRef.productId && !user?.isSuperuser) {
      updateProductStatusSeen();
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [productQuery, product, uploading]);

  useEffect(() => {
    console.log('gcode set to: ', gcode)
  }, [gcode])

  if (!product) {
    return null
  }

  return (  
    <div className='view product'>
      <div className='product-container'>
        <div className={`product-model-preview-container ${viewerExpanded ? 'expanded' : ''}`}>
          <Button variant='minimal' className='absolute right-4 bottom-4 z-50' onClick={() => expandViewer(!viewerExpanded)}>
            {viewerExpanded ?
              <Minimize className='h-4 w-4'/>
              :
              <Fullscreen className='h-4 w-4'/>
            }
          </Button>
          <OrganisationSelect 
            productOrganisations={product?.organisations ? product.organisations : []} 
            toggleOrganisation={toggleOrganisationOnProduct} 
            productLoading={productQuery.loading}
            access={user?.userId === product?.createdBy?.userId}
            buttonPositioningClasses='absolute left-6 top-[30px] z-10'/>
          <GroupSelect 
            productGroup={product?.groupId} 
            selectCallback={(groupId: string) =>  updateProductGroup(groupId)} 
            access={user?.userId === product?.createdBy?.userId} />
          {files && product?.productVersion?.length ? <VersionSelect productRef={productRef} currentVersionId={product?.productVersion[0].versionId} existingFiles={files} selectCallback={() => { product.productVersion && navigate('/product/' + product?.productVersion[0]?.reference); productQuery.refetch(); }} /> : null }
          <div className={`viewer-vignette ${viewerExpanded ? 'hidden' : ''}`}></div>
          <div className='hint-container disappearing absolute w-full flex justify-center align-bottom bottom-14 z-10'>
            <div className='hint new-marker text-xs flex gap-1 items-center bg-bw-highlight/80 text-white'><Info className='h-3 w-3'/>Click anywhere on the model to add a new marker.</div>
          </div>
          <div className={files.length > 1 ? 'viewer-nav-container' : 'viewer-nav-container hidden'}>
            <div className='viewer-nav-item left h-16 w-16' onClick={showPreviousFile}>
              <ChevronLeft className='h-6 w-6' />
            </div>
            <div className='viewer-nav-item right h-16 w-16' onClick={showNextFile}>
              <ChevronRight className='h-6 w-6' />
            </div>
          </div>
          <div className='viewer-file-indicator-container'>            
            {product?.filemodelSet?.filter((filemodelSet: any) => 
              filemodelSet.presignedUrl.toLowerCase().includes('.stl') || filemodelSet.presignedUrl.toLowerCase().includes('.gcode'))
                ?.map((file: any, key: number) => 
                  <div onClick={() => setActivePreview(key)} key={key} className={key === activePreview 
                    ? 'viewer-file-indicator-item active' : 'viewer-file-indicator-item'}>
                      <div className={helpers.extractFileTypeFromFilename(file.fileName) + ' viewer-file-indicator-inner-circle'}></div>
                  </div>
            )}
          </div>
          <div className='stl-viewer-container'>
            {files.filter(helpers.filters.isFileStl)?.map((file: FileType, index: number) => 
              file.presignedUrl.toLowerCase().includes('.stl') 
                ? <div className='viewer stl' key={index} style={{transform: `translateX(${-activePreview * 100}%)`}}>
                    <div className='file-info text-xs text-bw-green'>{file.fileName}</div>
                    <STLViewer url={file.presignedUrl} fileName={file.fileName} fileId={file.fileId}/>
                  </div> 
                : null 
            )}
            <div className='viewer gcode' style={{transform: `translateX(${-activePreview * 100}%)`}}>
              <GCodeViewer gcodeFile={gcode} nozzleDiameter={gcode?.fileattributesmodelSet ? gcode.fileattributesmodelSet[0]?.nozzleSize : undefined} />
            </div>
{/* 
            {files.filter(helpers.filters.isFileGcode)?.map((file: FileType, index: number) => 
              file.presignedUrl.toLowerCase().includes('.gcode') && file.fileattributesmodelSet
                ? <div className='viewer gcode' key={index} style={{transform: `translateX(${-activePreview * 100}%)`}}>
                    <GCodeViewer gcodeFile={file} nozzleDiameter={file.fileattributesmodelSet[0]?.nozzleSize} />
                  </div> 
                : null 
            )}
             */}
          </div>
        </div>
        <ProductDetails 
          product={product} 
          isSharedWithYou={isSharedWithYou}
          files={files}
          classNames={viewerExpanded ? 'hidden' : ''}
          filesToUpload={filesToUpload}
          setFilesToUpload={setFilesToUpload}
          user={user ? user : undefined}
          refetchProduct={(reloadFiles: boolean) => refetchProduct(reloadFiles)}
        />
        
        <ProductActions userIsSuperuser={user?.isSuperuser ? true : false} 
          productStatus={product?.status as ProductStatus} 
          files={files}
          filesToUpload={filesToUpload}
          updateWithNewFiles={() => addFilesToProduct()}
          uploading={uploading} 
          classNames={viewerExpanded ? 'hidden' : ''}
          updateProductStatus={(toStatus: ProductStatus) => updateProductStatus(toStatus)}
        />
      </div>
    </div>
  );
}

export default Product;
