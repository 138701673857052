import React, { useEffect, useState } from 'react'
import { Button } from '@/global-components/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/global-components/components/ui/tooltip"
import { useQuery, useMutation } from '@apollo/client';
import api from "@/api/bw-api"
import { Organisation, ProductGroup } from "@/global-components/types"
import { useNavigate } from "react-router-dom"
import { useToast } from "@/global-components/components/ui/use-toast";
import { Building, Factory, Info, Plus, PlusCircle, User2, XCircle } from 'lucide-react';
import InfoTooltip from '@/global-components/components/bw/InfoTooltip';


type GroupOrgsProps = {
  group: ProductGroup;
  refetchGroup: Function;
  groupLoading: boolean;
}

const GroupOrgs = ({group, refetchGroup, groupLoading}: GroupOrgsProps): JSX.Element | null => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<number>(0)
  const [updatingRights, setUpdatingRights] = useState<boolean>(false)
  const [organisations, setOrganisations] = useState<Organisation[]>([])
  const [groupOrganisations, setGroupOrganisations] = useState<Organisation[]>(group.organisations || [])
  const organisationsQuery = useQuery(api.organisations.queries.GET_ORGANISATIONS);
  const [updateGroup] = useMutation(api.products.mutations.UPDATE_GROUP);

  useEffect(() => {
    setOrganisations(organisationsQuery.data?.organisations)
  }, [organisationsQuery?.data, organisationsQuery])

  useEffect(() => {
    setUpdatingRights(groupLoading)
  }, [groupLoading])

  useEffect(() => {
    setGroupOrganisations(group.organisations)
  }, [group, group.organisations]) 

  const addGroupToOrganisation = (id: string) => {
    updateGroup({variables: {groupId: group.groupId, addToOrganisationId: id}})
      .then((result: any) => {
        if (result.data.updateGroup.success) {
          toast({
            title: "Group now available to organisation",
            variant: "success",
            duration: 2000
          })
          organisationsQuery.refetch()
          refetchGroup()
        } else {
          toast({
            title: "Something went wrong",
            variant: "destructive",
            duration: 2000
          })
        }
      })
  }

  const removeGroupFromOrganisation = (id: string) => {
    updateGroup({variables: {groupId: group.groupId, removeFromOrganisationId: id}})
    .then((result: any) => {
      if (result.data.updateGroup.success) {
        toast({
          title: "Organisations access from group has been removed",
          variant: "success",
          duration: 2000
        })
        organisationsQuery.refetch()
        refetchGroup()
      } else {
        toast({
          title: "Something went wrong",
          variant: "destructive",
          duration: 2000
        })
      }
    })
  }

  return (
    <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <DialogTrigger asChild>
        <Button variant='minimal' size='sm' className='gap-1 p-0' onClick={(e) => e.preventDefault}><Building className="h-4 w-4" /></Button>
      </DialogTrigger>
      <DialogContent className='transform-gpu'>
        <DialogHeader className='flex flex-row items-center gap-2'>
          Manage Organisations' Access to this Project 
          <InfoTooltip>
            You can allow organisations to have access to all products within this project. This allows people, who are part of that organisation to see all products added to it.
          </InfoTooltip>
        </DialogHeader>
        <div className='flex flex-col gap-1'>
          {organisations
            ?.map((organisation) => (
            <div key={organisation.organisationId} className='items-center group flex justify-between text-bw-green'>
              <div className="font-medium flex items-center gap-2">
                {organisation.name}
                <div className="opacity-50 text-xs flex items-center"> <User2 className="h-3 w-3" strokeWidth={3} /> {organisation.memberships?.length}</div> 
              </div> 
              <div className="flex items-center gap-2">
                {groupOrganisations?.find(grpOrg => grpOrg.organisationId === organisation.organisationId) ? 
                  <Button variant='destructive' disabled={updatingRights} size='sm'  onClick={() => removeGroupFromOrganisation(organisation.organisationId)}>Revoke</Button>
                  :
                  <Button variant='bwconfirm' disabled={updatingRights} size='sm'  onClick={() => addGroupToOrganisation(organisation.organisationId)}>Allow</Button>
                }
              </div>
            </div>
          ))}
          <Button variant='outline' className='items-center text-bw-green self-end mt-4' onClick={() => navigate("/account")}>
            <Plus className='h-4 w-4 mr-1' /> Create New Organisation
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default GroupOrgs