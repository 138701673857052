import React, { useState, useEffect } from 'react'
import { Grid} from '@react-three/drei';

type FloorProps = {
  grid: boolean;
  size: number;
}

const Floor = ({grid, size}: FloorProps): JSX.Element => {
  const gridConfig = {
      gridSize: [20, 20],
      cellSize: 5,
      cellThickness: 0.3,
      cellColor: '#aaaaaa',
      sectionSize: 10,
      sectionThickness: 1, 
      sectionColor: '#dddddd',
      fadeDistance: 800,
      fadeStrength: 2,
      followCamera: true,
      infiniteGrid: true
  }
  
  if (grid) {
    return (
      <Grid position={[size, size, 0]} rotation={[Math.PI/2, 0, 0]} args={[20, 20]} {...gridConfig}/>
    )
  } else {
    return (
      <mesh rotation={[0, 0, 0]} position={[size/2, size/2, 0]} receiveShadow>
        <planeGeometry args={[size, size]} />
        <meshStandardMaterial color={'#eeeeee'}/>
      </mesh>
    )
  }
}

export default Floor