import React, {useState, useEffect} from 'react'

const ImageOverlay = (props: {url: string | null, callback: Function}) => {
  const close = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.callback();
    }
  }
  useEffect(() => {
    if (props.url) {
      window.addEventListener('keydown', close)
    } else {
      window.removeEventListener('keydown', close)
    }
    return () => {
      window.removeEventListener('keydown', close)
    }
  }, [props.url])
  
  return (
    <div className={`fixed w-[100vw] h-[100vh] left-0 top-0 z-50 flex items-center justify-center p-24 overflow-hidden transition-opacity ${props.url ? 'bg-bw-pale-sage/50 backdrop-blur-sm opacity-100' : 'pointer-events-none opacity-0'}`}
          onClick={() => props.callback()}>
      <div className={`${props.url ? '' : 'scale-95 opacity-0'} rounded-md max-w-full max-h-full w-full h-full  transition-all flex justify-center items-center`}>
        <img src={props.url ? props.url : ''} className='max-w-full max-h-full shadow-2xl rounded-md'/>
      </div>
    </div>
  )
}

export default ImageOverlay