import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/global-components/components/ui/dropdown-menu"
import { Button } from '@/global-components/components/ui/button'
import './Dropdown.scss';
import { Cookie, UserCircle, UserCircle2 } from "lucide-react"

export type DropdownItemProps = {
  itemName: string;
  active: boolean;
  hidden?: boolean;
  toggleStatus?: boolean;
  itemNameDeactivated?: string;
  action? : any;
  icon? : JSX.Element;
  isSeperator?: boolean;
  isHeadline?: boolean;
  itemShortcut?: string;
}

export type DropdownProps = {
  buttonTitle: string;
  buttonIcon: any;
  items: DropdownItemProps[];
  align?: 'start' | 'end' | 'center';
}


const Dropdown = (props: DropdownProps) => {
  return (
    <div className='dropdown'>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant='ghost' className='text-xs flex gap-1' size='sm'><UserCircle2 className='h-4 w-4'  />{props.buttonTitle}</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='bg-white border-none shadow-2xl text-sm' align={props.align}>
          {props.items.map((item:DropdownItemProps, index: number) => {
            if (item.hidden) {
              return null
            } else if (item.isSeperator) {
              return (
                <DropdownMenuSeparator key={index} />
              )
            } else {
              return (
                <DropdownMenuItem onClick={item.action} key={index} className='cursor-pointer flex gap-1 pl-3 pr-3 pt-2 pb-2 items-center hover:bg-bw-pale-sage focus:bg-bw-pale-sage'>
                  {item.icon ? (item.toggleStatus !== false ? item.icon : <span className="opacity-30">{item.icon}</span>) : null}
                  <span className='dropdown-item-text font-medium text-sm'>
                    {item.toggleStatus === false ? item.itemNameDeactivated : item.itemName}
                  </span>
                </DropdownMenuItem>
              )
            }
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
};

export default Dropdown;