import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';

const SchoolView = (): React.JSX.Element => {
  const { user } = useAuth();

  return (
    <div className='view school'>
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className='text-2xl text-bw-green flex gap-1 items-center'>
          Welcome to the DFAM Education portal, {user?.firstName}
        </h1>
      </div>
    </div>
  )
}

export default SchoolView