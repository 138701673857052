import React, { useState, useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { Sphere } from '@react-three/drei';
import * as THREE from 'three';

type SetOrbitProps = {
  cameraControls: any;
  mesh: any;
}

const SetOrbit = ({cameraControls, mesh}: SetOrbitProps): JSX.Element => {
  const [orbitCenterPosition, setOrbitCenterPosition] = useState<THREE.Vector3>(new THREE.Vector3());
  const { gl, raycaster } = useThree();
  const normalizedMouse: THREE.Vector2 = new THREE.Vector2();
  
  // Refs to store the latest mesh and cameraControls values
  const meshRef = useRef(mesh);
  const cameraControlsRef = useRef(cameraControls);

  // Update the refs whenever the props change
  useEffect(() => {
    meshRef.current = mesh;
    cameraControlsRef.current = cameraControls;
  }, [mesh, cameraControls]);

  useEffect(() => {
    const eventListener = (event: any) => {
      setOrbitPoint(event.clientX, event.clientY);
    };

    gl.domElement.addEventListener('mousedown', eventListener);

    return () => {
      gl.domElement.removeEventListener('mousedown', eventListener);
    }
  }, []);

  const setOrbitPoint = (mouseX: number, mouseY: number) => {  
    if (!cameraControlsRef.current.current || !meshRef.current) return;
    const elRect = gl.domElement.getBoundingClientRect();
    const canvasX = mouseX - elRect.left;
    const canvasY = mouseY - elRect.top;

    normalizedMouse.set(
      (canvasX / elRect.width) * 2.0 - 1.0,
      ((elRect.height - canvasY) / elRect.height) * 2.0 - 1.0
    );

    cameraControlsRef.current.current.camera.updateMatrixWorld();
    raycaster.setFromCamera(normalizedMouse, cameraControlsRef.current.current.camera);
    raycaster.firstHitOnly = true;
    const intersections = raycaster.intersectObjects([meshRef.current]);
  
    if (intersections.length !== 0) {
      setOrbitCenterPosition(intersections[0].point);
      cameraControlsRef.current.current.setOrbitPoint(
        intersections[0].point.x,
        intersections[0].point.y,
        intersections[0].point.z
      );
    }
  };

  return (
    <Sphere position={orbitCenterPosition} scale={[0.15, 0.15, 0.15]}>
      <meshBasicMaterial color={'#ffffff'} />
    </Sphere>
  );
};

export default SetOrbit;
