// React imports
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';

// Context and API imports
import { useAuth } from '../../context/auth';
import { User, PrinterType, PrintRequest, UserPreferences } from '@/global-components/types'
import api from '../../api/bw-api';

// Component imports
import helpers from "@/global-components/components/helpers"
import Dropdown, { DropdownItemProps, DropdownProps } from '@/global-components/components/dropdown/Dropdown';
import SubmitNewProduct from '../submitNewProduct/SubmitNewProduct';
import { Menu, UserCircle2, LayoutGrid, Search, Home, Factory, Dot, Bot, LifeBuoy, LogOut, Layers, XCircle, ListTodo, Cookie, Shell, Target, GraduationCap, Microscope, Library, ArrowLeft } from "lucide-react";
import { Button } from "@/global-components/components/ui/button";
import ProgressBar from '@/global-components/components/bw/progressBar/ProgressBar';
import { Input } from '@/global-components/components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/global-components/components/ui/tooltip"
import { useToast } from "@/global-components/components/ui/use-toast";


// Styles
import './Navbar.scss';


export type NavbarProps = {
}

const Navbar = (props: NavbarProps) =>  {
  const [factoryPopoverOpen, setFactoryPopoverOpen] = useState<boolean>(false);
  const [printers, setPrinters] = useState<PrinterType[]>([]);
  const [userPrintRequestsOpen, setUserPrintRequestsOpen] = useState<boolean>(false);
  const [userPrintRequests, setUserPrintRequests] = useState<PrintRequest[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { toast } = useToast()


  const { setLoggedIn, loggedIn, setUser, user } = useAuth();

  const [isHome, setIsHome] = useState<boolean>(location.pathname === '/');

  const printersQuery = useQuery(api.printers.queries.GET_PRINTER_STATUS_FOR_FACTORY, {
    variables: {
      factoryId: 3
    },
    pollInterval: 5000,
    // skip: !loggedIn
    skip: true
  });

  useEffect(() => {
    if (printersQuery?.data) {
      setPrinters(printersQuery.data.printersInLocation);
    }
  },[printersQuery]);

  const [deletePrintRequest] = useMutation(api.printRequests.mutations.DELETE_PRINT_REQUEST);
  const printRequestQuery = useQuery(api.printRequests.queries.GET_PRINT_REQUESTS_FOR_USER, {
    pollInterval: 5000,
    // skip: !loggedIn
    skip: true
  });

  const filterActivePrintRequests = (printRequests: PrintRequest[]): PrintRequest[] => {
    return printRequests
      ?.filter((printRequest: PrintRequest) => printRequest.jobsCompleted !== printRequest.quantity)
  }

  useEffect(() => {
    if (printRequestQuery.data) {
      setUserPrintRequests(filterActivePrintRequests(printRequestQuery.data.userPrintRequests))
    }
  }, [printRequestQuery.data])

  const [userPreferences, setUserPreferences] = useState<UserPreferences | undefined>(undefined)
  const [updateUserPreferences] = useMutation(api.user.mutations.UPDATE_USER_PREFERENCES)

  useEffect(() => {
    setUserPreferences(user?.userPreferences)
  }, [user])

  const returnStatusColour = (status: string): string => {
    switch(status.toLowerCase()) {
      case 'ready':
        return ' bg-ui-confirmation-green';
        break;
      case 'printing':
        return ' bg-ui-confirmation-green animate-pulse';
        break;
      case 'inactive':
        return ' bg-bw-grey';
        break;
      case 'pause':
        return ' bg-bw-grey';
        break;
      case 'error':
        return ' bg-ui-denial-red';
        break;
      case 'inoperational':
        return ' bg-bw-grey'
        break;
      default:
        return '';
        break;
    }
  }

  const logOut = () => {
    setLoggedIn(false);
    api.user.local.removeToken();
    client.clearStore();
    window.location.reload();
  } 
  
  const toggleReviewerMode = () => {
    updateUserPreferences({
      variables: {
        reviewerMode: userPreferences && userPreferences.reviewerMode !== null ? !userPreferences.reviewerMode : false
      }
    }).then((result: any) => {
      helpers.log('result from set reviewer mode ', result)
      if (result.data.updateUserPreferences.success) {
        setUserPreferences(result.data.updateUserPreferences.updatedUserPreferences)
        if (user) {
          setUser({
            ...user,
            userPreferences: result.data.updateUserPreferences.updatedUserPreferences
          })
        }
      } else {
        helpers.log('error ' + result)
      }
    }).catch((error: any) => {
      helpers.log('error while upda ting user prefs ', error)
    })
  }

  const firstName: string = user?.firstName ? user.firstName : 'n/a'

  const accountDropdown: DropdownProps = {
    buttonTitle: firstName + (user?.isSuperuser 
      ? ' (su)'
      :
      user?.isStaff ? 
        ' (staff)' 
        : 
        ''),
    buttonIcon: '',
    align: 'end',
    items: [
      {
        itemName: 'Account',
        active: true,
        icon: <Cookie className='w-4 h-4 mr-1' />,
        action: () => navigate('/account')
      },
      {
        itemName: 'Hide Client Products',
        itemNameDeactivated: 'Show Client Products',
        active: false,
        hidden: !user?.isSuperuser,
        toggleStatus: userPreferences?.reviewerMode,
        icon: <ListTodo className='w-4 h-4 mr-1'/>,
        action: () => toggleReviewerMode()
      },
      {
        itemName: '',
        active: true,
        isSeperator: true
      },
      {
        itemName: 'Join our Discord',
        active: true,
        icon: <Bot className='w-4 h-4 mr-1' />,
        action: () => window.open('https://discord.gg/8Bxv55Ss', '_blank')
      },
      {
        itemName: 'Tell us what you think',
        active: true,
        icon: <LifeBuoy className='w-4 h-4 mr-1'/>,
        action: () => window.open('https://forms.gle/qe6SnmcT3wGdexng7', '_blank')
      },
      {
        itemName: '',
        active: true,
        isSeperator: true
      },
      {
        itemName: 'Log Out',
        action: logOut,
        icon: <LogOut className='w-4 h4 mr-1'/>,
        active: true
      },
    ]
  }

  const getActionItems = (): JSX.Element | null => {
    if (loggedIn) {
      if(user?.isStaff) {
        return <div className='flex items-center gap-2'>
          <SubmitNewProduct isStaff={user?.isStaff}/>
          <Dot className='w-4 h-4 opacity-30' />
        </div>
      } else {
        return <div className='flex items-center gap-2'>
          {/* <Button disabled variant="bwonbwgreen" size='sm'>Submit a Brief</Button> */}
          {/* <Button disabled variant="bwonbwgreen" size='sm'>Fast Prototype</Button> */}
          <SubmitNewProduct isStaff={user?.isStaff}/>
          <Dot className='w-4 h-4 opacity-30' />
        </div>
      }
    } else {
      return null;
    }
  }

  const handleDeletePrintRequest = (requestId: string) => {
    deletePrintRequest({variables: {requestId: requestId}})
      .then((result: any) => {
        if(result.data.deletePrintRequest.success) {
          toast({
            title: "Delete Print Request",
            description: "Successfully deleted print request.",
            variant: "success",
            duration: 3000
          })
        } else {
          toast({
            title: "Delete Print Request",
            variant: 'destructive',
            description: "Error trying to delete the print request: " + result.data.deletePrintRequest.error,
          })
        }
      })
      .catch(err => {
        
        toast({
          title: "Error",
          variant: 'destructive',
          description: "An error occured while connecting to the server, please try again.",
          duration: 5000
        })
      })
      .finally(() => {
        printRequestQuery.refetch();
      })
  }

  const getPrintRequestPopover = (): JSX.Element => {
    return <Popover open={userPrintRequestsOpen} onOpenChange={setUserPrintRequestsOpen}>
      <PopoverTrigger asChild>
        {loggedIn ?<Button disabled={userPrintRequests?.length === 0 || process.env.REACT_APP_ENV==='beta'} variant="ghost" size='sm' className='factory-status flex gap-1 text-xs'>
          <Layers className='w-4 h-4' /> {userPrintRequests.length}
        </Button> : null}
      </PopoverTrigger>
      <PopoverContent className="w-72 flex flex-col gap-2 text-sm" sideOffset={5} align='end'>
        <div className='font-bold mb-2'>Your Active Print Requests</div>
        <div className='flex flex-col gap-4'>
          {userPrintRequests?.map((request: PrintRequest, index: number) => (
            <div className={request.interupted ? 'flex flex-col gap-1 opacity-30' : 'flex flex-col gap-1'} key={index}>
              <ProgressBar bright height={4} progress={(Number(request.jobsCompleted) / Number(request.quantity) * 100)} />
              <div className='flex justify-between gap-1 text-sm items-center'>
                <div>
                  <div className='flex gap-1 grow'>
                    <div>{request.jobsCompleted}/{request.quantity}</div>
                    <div className='font-bold truncate'>{request.file.fileName}</div>
                  </div>
                  <div className='print-request-details flex gap-2 relative text-bw-green/30'>
                    <div className='text-xs'>Location: <b>{request.factory?.name}</b> - </div>
                    {/*<div className='text-xs'>Requested: {helpers.formatDateToDDMMYY(request.issuedAt)}</div>*/}
                  </div>
                </div>
                <div className='text-right'>
                  <Button variant='minimal' size='smallicon' onClick={() => handleDeletePrintRequest(request.requestId)}>
                    <XCircle className='h-4 w-4'></XCircle>
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  }

  const canGoBack = (): boolean => {
    return location.key !== 'default'
  };

  const insideProductLibrary = (): boolean => {
    return location.pathname === '/' || location.pathname.includes('project') || location.pathname.includes('product')
  }

  return (  
    <div className="navbar bg-bw-dark-tinted-green">
      <div className='left h-full'>
        {/*<button className='hamburger'>
          <Menu className="h-4 w-4" />
        </button>*/}
        {loggedIn ? 
          <div className='flex gap-6 h-full'>
            <div className={`flex items-center ${!canGoBack() && 'opacity-30'}`}>
              <button title="Products" className='home flex gap-1 items-center' disabled={!canGoBack()} onClick={() => navigate(-1)}>
                <ArrowLeft className="h-4 w-4"/>
              </button>
            </div>
            <div className={`h-full border border-0 border-b-2 flex items-center ${insideProductLibrary()  ? 'border-bw-background-grey' : 'border-transparent'}`}>
              <button title="Products" className='home flex gap-1 items-center' disabled={location.pathname === '/'} onClick={() => navigate('/')}>
                <LayoutGrid className="h-4 w-4"/>
              </button>
            </div>
            <div className={`h-full border border-0 border-b-2 flex items-center ${location.pathname === '/materials' ? 'border-bw-background-grey' : 'border-transparent'}`}>
              <button title="Materials" className={location.pathname === '/materials' ? 'home flex gap-1 items-center' : 'home opacity-90 cursor-pointer flex gap-1 items-center hover:scale-110'} disabled={location.pathname === '/materials'} onClick={() => navigate('/materials')}>
                <Target className="h-4 w-4"/> 
              </button>
              </div>
              <div className={`h-full border border-0 border-b-2 flex items-center ${location.pathname === '/school' ? 'border-bw-background-grey' : 'border-transparent'}`}>
              <button title="DFAM School" className={location.pathname === '/school' ? 'home flex gap-1 items-center' : 'home opacity-90 cursor-pointer flex gap-1 items-center hover:scale-110'} disabled={location.pathname === '/school'} onClick={() => navigate('/school')}>
                <Library className="h-4 w-4"/> 
              </button>
            </div>
          </div>
          : null
        }
        {useAuth().loggedIn ? <div className='seperator hidden'></div> : null}
        {useAuth().loggedIn ? <div className='search-input-container w-[500px] hidden'>
          <Input type='text' placeholder='Soon you can search and find ...' className='text-bw-pale-sage opacity-30 placeholder:text-bw-pale-sage bg-transparent border-none text-xs font-medium focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:opacity-100'  />
          <Search className="icon h-4 w-4" />
        </div>
        : null
        }
      </div>
      <div className='right flex items-center gap-1'>
        {getActionItems()}

        {/* <Popover open={factoryPopoverOpen} onOpenChange={setFactoryPopoverOpen}>
          <PopoverTrigger asChild>
            {useAuth().loggedIn ?<Button disabled={printers.length === 0 || process.env.REACT_APP_ENV==='beta'} title='The factory feature will be available in the future.' variant="ghost" size='sm' className='factory-status flex gap-1 text-xs'>
              <Factory className='w-4 h-4' /> 1
            </Button> : null}
          </PopoverTrigger>
          <PopoverContent className="w-64 flex flex-col gap-2 text-sm" sideOffset={5}>
            <div className='font-bold mb-2'>SSF PlusX ({printers.length})</div>
            <div className=''>
              {printers.map((printer: PrinterType, index: number) => (
                <div className='printer flex gap-2 items-center text-bw-green' key={index}>
                  <div className={'w-2 h-2 rounded-xl' + returnStatusColour(printer.status)}></div> 
                  <div className={(printer.status.toLowerCase() === 'inactive' || printer.status.toLowerCase() === 'inoperational') ? 'opacity-30 flex gap-1' : 'flex gap-1'}>
                    {printer.name} <div className='opacity-30'>ID: {printer.printerId}</div>
                  </div>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover> */}

        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild className='cursor-default'>
              <div className='factory-status pl-2 pr-2 opacity-50 flex gap-1 text-xs cursor-default'>
                <Factory className='w-4 h-4' /> 0
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Factories will be visible here in the future.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        
        {!useAuth().loggedIn && <Button variant="ghost" size='sm' className='text-xs' onClick={() => window.open('https://discord.gg/8Bxv55Ss', '_blank')}><Bot className='w-4 h4 mr-1' />Join our Discord</Button>}
        {!useAuth().loggedIn && <Button variant="ghost" size='sm' className='text-xs' onClick={() => window.open('https://forms.gle/qe6SnmcT3wGdexng7', '_blank')}><LifeBuoy className='w-4 h4 mr-1'/>Feedback</Button>}
        <Dot className='w-4 h-4 opacity-30' />

        {/* {getPrintRequestPopover()} */}
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild className='cursor-default'>
              <div className='factory-status pl-2 pr-2 opacity-50 flex gap-1 text-xs cursor-default'>
                <Layers className='w-4 h-4' /> 0
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>In the future, you will find your print requests here</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Dot className='w-4 h-4 opacity-30' />
        {useAuth().loggedIn ? <Dropdown {...accountDropdown} /> : <Button variant="bwonbwgreen" size='sm' className='text-xs' onClick={() => navigate('/login')}>Log In</Button>}
      </div>
    </div>
  )
}

export default Navbar;