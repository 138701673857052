// React imports
import { useState } from 'react';
import { useMutation } from '@apollo/client';

// Context and API imports
import api from '../../../api/bw-api';

// Component imports
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog";
import { Input } from '@/global-components/components/ui/input'
import { Checkbox } from '@/global-components/components/ui/checkbox'
import { Button } from "@/global-components/components/ui/button";
import { useToast } from "@/global-components/components/ui/use-toast";
import { Info, Plus } from "lucide-react";
import { FileType } from '@/global-components/types'
import { update } from 'lodash';
import CustomFileUpload from '@/components/customFileUpload/CustomFileUpload';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent} from '@/global-components/components/ui/tooltip'

type CreateVersionProps = {
  productRef: string | undefined;
  existingFiles: FileType[];
  callback: Function;
}

type CarryOverFile = FileType & {
  carryOver: boolean;
}


const CreateVersion = (props: CreateVersionProps) => {
  const [open, setOpen] = useState(false)
  const [formValid, setFormValid] = useState<boolean>(false)
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [carryOverFiles, setCarryOverFiles] = useState<CarryOverFile[]>(
    props.existingFiles.map(existingFile => ({ ...existingFile, carryOver: true }))
  );
  const [versionTag, setVersionTag] = useState<string>('');
  const [createVersion] = useMutation(api.products.mutations.CREATE_NEW_PRODUCT_VERSION);
  const { toast } = useToast()
  
  const changeCarryOverOnFile = (index: number, checked: boolean) => {
    const updatedFiles = [...carryOverFiles]
    updatedFiles[index].carryOver = checked;
    
    setCarryOverFiles(updatedFiles);
  }

  const handleFileChange = (files: File[]) => {
    setNewFiles(files);
  }

  const getCarriedFilesIds = ():string[] => {
    return carryOverFiles.filter(file => file.carryOver).map(file => file.fileId);
  }

  const submitCreateVersion = () => {
    
    createVersion({ variables: { reference: props.productRef, versionTag: versionTag, files: newFiles, carriedFiles: getCarriedFilesIds() }})
        .then((result : any) => {
          
          if (result.data.newProductVersion.success) {
            toast({
              title: "Version created successfully",
              variant: 'success',
              duration: 3000
            })
            if(props.callback) {
              props.callback(true);
            }
          } else {
            toast({
              title: "Sorry, something went wrong. Please try again later.",
              variant: 'destructive',
              duration: 8000
            })
          }
          setOpen(false);
        })
        .catch((error : any) => { })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='bwsecondary' size="sm" className='w-full text-center items-center'><Plus className='h-4 w-4 mr-1' /> Create New Version</Button>
      </DialogTrigger>
      <DialogContent className='max-w-l'>
        <DialogHeader>
          <DialogTitle className='flex gap-2'>
            Create a new Version 
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className='h-4 w-4' />
                </TooltipTrigger>
                <TooltipContent>
                  <p>New versions will be always created off of the latest version.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </DialogTitle> 
        </DialogHeader>
        <div>
          <div className='label text-xs pl-2 mb-1'>Version Tag</div>
          <Input type='text' name='versionTag' placeholder="Tag your version (max 50 Chars)" value={versionTag} onChange={(e) => setVersionTag(e.target.value)} />
        </div>
        <div className='flex gap-2 items-center'>
          <div className='flex-1 h-full'>
            <div className='label text-xs pl-2 mb-1'>Carry Over Files</div>
            <div className='carry-over-files border border-bw-grey rounded-sm bg-bw-pale-sage/30 p-4 text-sm flex flex-col gap-2'>
              {carryOverFiles.map((carryOverFile: CarryOverFile, index: number) => (
                <div key={index} className='flex items-center gap-2'>
                  <Checkbox checked={carryOverFile.carryOver} onCheckedChange={(checked: boolean) => changeCarryOverOnFile(index, checked)}/>
                  {carryOverFile.fileName}
                </div>
              ))}
            </div>
          </div>
          <div className='flex-1 h-full'>
            <div className='label text-xs pl-2 mb-1'>New Files</div>
            <CustomFileUpload onFilesChange={handleFileChange} type='anything' small />
          </div>
        </div>
        <DialogFooter>
          {/*<Button variant="bwsecondary" onClick={() => setOpen(false)}>Cancel</Button>*/}
          <Button disabled={formValid} variant="bwprimary" onClick={submitCreateVersion}>Create Version</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CreateVersion;
