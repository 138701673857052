import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom";
import { Check, ChevronDown, ChevronsUpDown, GitCommit, Plus } from "lucide-react"
import api from '../../../api/bw-api';

import { cn } from "@/lib/utils"
import { Button } from "@/global-components/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandSeparator,
  CommandItem,
} from "@/global-components/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import CreateVersion from './CreateVersion'
import { useMutation, useQuery } from '@apollo/client';

import { Version, FileType } from '@/global-components/types'

type VersionSelectProps = {
  productRef: string | undefined;
  currentVersionId: string | undefined;
  existingFiles: FileType[];
  selectCallback: Function;
}


const VersionSelect = ({productRef, currentVersionId, existingFiles, selectCallback}: VersionSelectProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>(currentVersionId)
  const [versions, setVersions] = useState<Version[]>([])

  const navigate = useNavigate();
  
  const versionsQuery = useQuery(api.products.queries.GET_VERSIONS_FOR_PRODUCT_REF, {
    variables: { reference: productRef },
    pollInterval: 5000,
    skip: !productRef
  })

  

  const newVersionCreated = () => {
    selectCallback(true);
    setOpen(false)
  }

  const navigateToVersion = (versionId: string) => {
    const versionNumber: number | undefined = versions.find((version: Version) => version.versionId === versionId)?.versionNumber
    if (versionNumber) {
      navigate('/product/' + productRef + '?v=' + versionNumber)
    }
  }

  useEffect(() => {
    if (versionsQuery.data?.versionsForProductRef) {
      setVersions(versionsQuery.data?.versionsForProductRef);
    }
  }, [versionsQuery])

  useEffect(() => {
    setValue(currentVersionId);
  }, [currentVersionId])

  return (
    <div className='absolute right-6 top-[80px] z-10'>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[350px] justify-between bg-transparent pl-3 pr-2 border border-solid border-bw-grey hover:bg-bw-pale-sage"
          >
            <div className="flex gap-2 items-center">
              
              <GitCommit className="h-4 w-4" />
              {versions.length ? 'Version ' + versions.find((version: Version) => version.versionId === currentVersionId)?.versionNumber
              : 'Version 1'}
            </div>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={open ? "w-[350px] p-0" : "w-[350px] p-0 bg-transparent"}>
          <Command className="bg-transparent data-[state=selected]:bg-bw-pale-sage">
            {/*<CommandInput placeholder="Search projects ..." />*/}
            <CommandEmpty>No previous versions</CommandEmpty>
            <CommandGroup>
              {versions.map((version: Version) => (
                <CommandItem
                  key={version.versionId}
                  value={version.versionId}
                  onSelect={(currentValue) => {
                    if (currentValue === value) {
                      return;
                    } else {
                      setValue(currentValue)
                      selectCallback(currentValue);
                      navigateToVersion(currentValue);
                      setOpen(false)
                    }
                  }}
                  className={value === version.versionId ? 'cursor-default items-center' : 'items-center'}
                >
                  <div className="flex flex-col gap-0">
                    <div>Version {version.versionNumber}</div>
                    <div className={`text-xs ${version.versionTag ? 'text-bw-green/70' : 'text-bw-green/30'}`}>{version.versionTag ? version.versionTag : 'No description' }</div>
                  </div>
                  <Check
                    className={cn(
                      "h-4 w-4",
                      value === version.versionId ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
                <CommandSeparator className='mt-1 mb-1'/>
                <CreateVersion callback={() => newVersionCreated()} productRef={productRef} existingFiles={existingFiles} />
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}


export default VersionSelect;