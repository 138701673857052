import { Info } from 'lucide-react'
import React, { ReactNode, useEffect, useState } from 'react'

type InfoTooltipProps = {
  children: ReactNode;
}

const InfoTooltip = ({children}: InfoTooltipProps) => {
  return (
    <div className='group relative'>
      <Info className='h-4 w-4' />
      <div className='absolute transform-gpu pointer-events-none top-1 -left-40 p-4 transition-all text-sm duration-100  max-w-80 w-80 bg-white text-bw-green rounded-md border border-bw-pale-sage shadow-2xl opacity-0 group-hover:opacity-100 group-hover:top-4'>
        {children}
      </div>
    </div>
  )
}

export default InfoTooltip