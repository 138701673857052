// React imports
import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from '@apollo/client';

// Context and API imports
import api from '../../api/bw-api';

// Type imports
import { User, ProductType, ProductGroup } from '@/global-components/types'

// Component imports
import { useAuth } from '../../context/auth';
import { useToast } from "@/global-components/components/ui/use-toast"
import ProductPreview from './ProductPreview';
import GroupPreview from './GroupPreview';
import SubmitNewProduct from '../submitNewProduct/SubmitNewProduct';

// Styles
import './UserProducts.scss'; 
import helpers from '@/global-components/components/helpers';

interface UserProductsProps {
  groupByProjects?: boolean;
  showStaffProducts?: boolean;
}

const UserProducts = (props: UserProductsProps) => {
  const { user } = useAuth()
  const { toast } = useToast()

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reload = queryParams.get('reload');
  const deletedProductId = queryParams.get('deletedProductId');
  const [loading, setLoading] = useState<boolean>(true)
  const [deletingProductWithId, setDeletingProductWithId] = useState<string | null>(null)

  const [gridColumns, setGridColumns] = useState<string>('grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8');
  

  const [products, setProducts] = useState<ProductType[]>([]);
  const [groups, setGroups] = useState<ProductGroup[]>([]);


  const getAllProductsForUserQuery = useQuery(api.products.queries.GET_PRODUCTS_FOR_USER, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: user?.userId,
      includeMessages: true,
      includeFiles: true
    },
    skip: !user
  });

  const getAllGroupsForUserQuery = useQuery(api.products.queries.GET_GROUPS, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
  })

  const sortProductsByStatus = (a: any, b: any): number => {
    const order: { [key: string]: number } = {
      'production': 1,
      'approved': 2,
      'unapproved': 3,
    };
  
    return order[a.status.toLowerCase()] - order[b.status.toLowerCase()];
  };

  const showOnlyLatestVersion = (product: any): boolean => {
    return product.latestVersion
  }

  const productsInGroup = (group: ProductGroup): ProductType[] => {
    return products.filter((product: ProductType) => product.groupId?.groupId === group.groupId);
  }

  const isUsersGroup = (group: ProductGroup, index: number, array: ProductGroup[]): boolean => {
    return group.createdBy.userId === user?.userId
  }


  useEffect(() => {
    setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    const handleResize = () => {
      setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (reload) {
      helpers.log('reloading products')
      setLoading(true)
      getAllProductsForUserQuery.refetch();
    }

    if (deletedProductId) {
      setDeletingProductWithId(deletedProductId)
    }
  }, [location]);

  useEffect(() => {
    if (getAllProductsForUserQuery.loading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [getAllProductsForUserQuery.loading])

  useEffect(() => {
    if (getAllProductsForUserQuery.data?.productsForUser)  {
      const sortedProducts: any[] = [...getAllProductsForUserQuery.data?.productsForUser].sort(sortProductsByStatus);
      const filteredProducts: ProductType[] = user?.isSuperuser ?
        sortedProducts.filter((product: any) => product.createdBy.userId === user?.userId)
          .filter(showOnlyLatestVersion)
        :
        sortedProducts.filter((product: any) => product.status.toLowerCase() === 'approved' || product.status.toLowerCase() === 'production')
          .filter(showOnlyLatestVersion)

      setProducts(filteredProducts);
    }
  }, [getAllProductsForUserQuery.data])

  useEffect(() => {
    if (getAllProductsForUserQuery.error) {
      toast({
        title: "Loading Problem",
        description: "We're really sorry, but there was a problem loading data form the server. Please try again later or contact software@batch.works.",
        variant: 'destructive'
      })
    }
  }, [getAllProductsForUserQuery.error])

  useEffect(() => {
    if (getAllGroupsForUserQuery.data?.groups) {
      setGroups(getAllGroupsForUserQuery.data.groups)
    }
  }, [getAllGroupsForUserQuery])

  if (products.length === 0) {
    <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
      <NewProduct />  
    </div>
  }

  return (
    <div className={`${loading ? '' : ''}`}>
      <div className={`text-xs text-bw-green/30 font-bold pl-4 mb-1`}>Active</div>
      {props.groupByProjects ?
        <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
          {groups.filter(isUsersGroup).map((group: ProductGroup, index: number) => (
            <GroupPreview
              key={index}
              group={group}
              products={productsInGroup(group)}
            />
          ))}
        </div>
        :
        <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
          {products.map((product: any, index: number) => (
            <ProductPreview 
              key={index}
              product={product}
              callback={(productId: string) => getAllProductsForUserQuery.refetch()}
              navigate={navigate} 
              index={index} 
            />
          ))}
          <NewProduct />  
        </div>
      }
    </div>
  )
}

export default UserProducts;


const NewProduct = () => {
  return (
    <SubmitNewProduct fromCard />
  )
}