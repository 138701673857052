import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';
import { Spin } from "react-cssfx-loading";
import { Pencil, Save, ChevronLeft, ChevronRight, ChevronDown, Info, XCircle, X, Check } from "lucide-react";

import { Separator } from '@radix-ui/react-select';

import api from '@/api/bw-api';
import helpers from '@/global-components/components/helpers'
import { ProductType, ProductStatus, FileType, PrintRequest, User, SlicerParameters } from '@/global-components/types'
import { useToast } from "@/global-components/components/ui/use-toast";

import { Badge } from "@/global-components/components/ui/badge";
import { Button } from "@/global-components/components/ui/button";
import { Textarea } from "@/global-components/components/ui/textarea";
import Filament from '@/global-components/components/bw/filament/Filament'
import ProgressBar from '@/global-components/components/bw/progressBar/ProgressBar';
import DownloadButton from '@/global-components/components/bw/DownloadButton';
import ImageOverlay from '@/global-components/components/bw/ImageOverlay';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/global-components/components/ui/collapsible";

import GcodeFileInfo from '@/components/productRelated/GcodeFileInfo';
import PrintProduct from '@/components/productRelated/printProduct/PrintProduct';
import MessagesPopover from '@/components/messagesPopover/MessagesPopover';
import CustomFileUpload from '@/components/customFileUpload/CustomFileUpload';

import ProductDelete from '../productDelete/ProductDelete';
import SlicerSettings from '@/components/productRelated/SlicerSetttings';
import EmissionsData from '@/components/EmissionsData';

type props = {
  product: ProductType;
  files: FileType[];
  filesToUpload: File[];
  user: User | undefined;
  classNames: string;
  setFilesToUpload: Function;
  refetchProduct: Function;
  isSharedWithYou?: boolean;
}

const ProductDetails = ({product, files, filesToUpload, setFilesToUpload, user, classNames, refetchProduct, isSharedWithYou}: props) => {
  const { toast } = useToast()
  const navigate = useNavigate();

  const [updateProductMutation] = useMutation(api.products.mutations.UPDATE_PRODUCT)
  const [editing, setEditing] = useState<boolean>(false);
  const [editedProduct, setEditedProduct] = useState<ProductType | undefined>(undefined);

  const [printRequestsOpen, setPrintRequestsOpen] = useState<boolean>(false);
  const [perferencesOpen, setPreferencesOpen] = useState(true)
  const [gcodeFilesOpen, setGcodeFilesOpen] = useState(true)
  const [stlFilesOpen, setStlFilesOpen] = useState(false)
  const [otherFilesOpen, setOtherFilesOpen] = useState(false)
  const [impactDataOpen, setImpactDataOpen] = useState<boolean>(true)

  const [slicerSettingsOpen, setSlicerSettingsOpen] = useState<boolean>(false)
  const [stl, setStl] = useState<FileType | null>(null)
  const [isSlicing, setIsSlicing] = useState<boolean>(false)
  const [slicerParameters, setSlicerParameters] = useState<SlicerParameters | null>(null)
  const [slicedExternally, setSlicedExternally] = useState<boolean>(false)

  const [imageOpen, setImageOpen] = useState<string | null>(null);
  
  const [updating, setUpdating] = useState(false);
  const [reloadKey, setReloadKey] = useState<number>(Date.now())

  const [deletePrintRequest] = useMutation(api.printRequests.mutations.DELETE_PRINT_REQUEST);
  const [resliceFile] = useMutation(api.products.mutations.RESLICE_FILE)

  const printRequestQuery = useQuery(api.printRequests.queries.GET_PRINT_REQUESTS_FOR_USER, {
    pollInterval: 60000
  });

  const fileSlicerQuery = useQuery(api.products.queries.FILE_SLICER, {
    variables: {
      stlFileId: stl?.fileId
    },
    pollInterval: 300,
    fetchPolicy: 'no-cache'
  })

  const toggleEditProduct = (cancel?: boolean) => {
    if (editing) {
      if (_.isEqual(product, editedProduct) || cancel) {
        setEditing(false)
        setEditedProduct(undefined)
      } else {
        if (!editedProduct?.description) {
          toast({
            title: "Can't update",
            variant: 'destructive',
            description: "Your product needs to have a description",
          })
          return
        }
        setUpdating(true)
        updateProductMutation({variables: { productId: product.productId, description: editedProduct?.description }})
          .then((result: any) => {
            helpers.log('updating product most likely successful: ', result);
            refetchProduct();
          })
          .catch((error : any) => { 
            helpers.log(error);
          })
          .finally(() => {
            setEditing(false)
            setEditedProduct(undefined)
            setUpdating(false)
          })
      }
    } else {
      const _product: ProductType = product;
      setEditedProduct(_product)
      setEditing(true);
    }
  }

  const updateProductDescription = (description: string) => {
    setEditedProduct(prevProduct => {
      if (!prevProduct) return prevProduct;
  
      return {
        ...prevProduct,
        description
      };
    });
  }

  const goBackToAllProducts = (deletedProductId: string) => {
    navigate(`/?reload=true&deletedProductId=${deletedProductId}`);
  }

  const getTotalPrintsForThisProduct = (printRequests: PrintRequest[]): number => {
    const productPrintRequests: PrintRequest[] = printRequests?.filter((printRequest: PrintRequest) => files.filter(helpers.filters.isFileGcode)?.some((gcode: any) => gcode.fileId === printRequest.file.fileId))
    return productPrintRequests?.reduce((totalCompleted: number, item: PrintRequest) => totalCompleted + item.jobsCompleted, 0) || 0;
  }

  const handleFilesChange = (_files: File[]) => {
    setFilesToUpload([..._files]);
  };

  const handleDeletePrintRequest = (requestId: string) => {
    deletePrintRequest({variables: {requestId: requestId}})
      .then((result: any) => {
        if(result.data.deletePrintRequest.success) {
          toast({
            title: "Delete Print Request",
            description: "Successfully deleted print request.",
            variant: "success",
            duration: 3000
          })
        } else {
          toast({
            title: "Delete Print Request",
            variant: 'destructive',
            description: "Error trying to delete the print request: " + result.data.deletePrintRequest.error,
          })
        }
      })
      .catch(err => {
        
        toast({
          title: "Error",
          variant: 'destructive',
          description: "An error occured while connecting to the server, please try again.",
          duration: 5000
        })
      })
      .finally(() => {
        printRequestQuery.refetch();
      })
  }

  const filterActivePrintRequestsForProduct = (printRequests: PrintRequest[]): PrintRequest[] => {
    return printRequests
      ?.filter((printRequest: PrintRequest) => printRequest.jobsCompleted !== printRequest.quantity)
      ?.filter((printRequest: PrintRequest) => files.filter(helpers.filters.isFileGcode)?.some((gcode: any) => gcode.fileId === printRequest.file.fileId))
  }

  useEffect(() => {
    setReloadKey(Date.now());
    const _stl: FileType = files.filter(helpers.filters.isFileStl)[0]
    setStl(_stl)
  }, [files])

  useEffect(() => {
    if (fileSlicerQuery.data) {
      console.log(fileSlicerQuery.data)
      setIsSlicing(fileSlicerQuery.data.fileSlicer.isSlicing)
      setSlicerParameters(fileSlicerQuery.data.fileSlicer)
      setSlicedExternally(false)
    } else {
      setSlicedExternally(true)
    }
  }, [fileSlicerQuery.data])

  useEffect(() => {
    if (!isSlicing) {
      refetchProduct(true)
    }
  }, [isSlicing])

  const resliceWith = (params: SlicerParameters, gcodeFileId: string) => {
    setSlicerParameters(params)
    resliceFile({variables: {
      productId: product.productId,
      stlFileId: files.filter(helpers.filters.isFileStl)[0].fileId,
      rotateX: params.rotateX,
      rotateY: params.rotateY,
      rotateZ: params.rotateZ,
      nozzleDiameter: params.nozzleDiameter,
      layerHeight: params.layerHeight,
      extrusionWidth: params.extrusionWidth,
      bottomSolidLayers: params.bottomSolidLayers,
      firstLayerHeight: params.firstLayerHeight,
      speed: params.speed,
      fanSpeed: params.fanSpeed,
      temperature: params.temperature
    }}).then((result: any) => {
      fileSlicerQuery.refetch()
      refetchProduct(true)
    }).catch((err: any) => {
      console.log('error: ', err)
    })
  }

  return (
    <div className={`product-details-container ${classNames}`}>
          <ImageOverlay url={imageOpen} callback={() => setImageOpen(null)}/>
          <div className='product-details-header'>
            <div>
              <h1 className='product-title text-2xl mt-[6px]'>{product?.productTitle} {isSharedWithYou && <span className='opacity-30'>(Shared with you)</span>}</h1>
              <div className='flex gap-2 items-center mt-2.5 relative text-bw-green'>
                <Badge variant={product?.status.toLowerCase() as ProductStatus}>{product?.status}</Badge>
                <div className='text-xs'>
                  Created: <b>{helpers.formatDateToDDMMYY(product?.createdAt, true)}</b>
                </div>
                {(product?.createdBy)   ?
                  <div className='text-xs'>
                    User: <b>{product.createdBy.firstName}</b>
                  </div>
                  : null 
                }
                {(product?.status.toLowerCase() === 'approved' || product?.status.toLowerCase() === 'production') ? 
                  <div className='text-xs'>
                    Printed: <b>{getTotalPrintsForThisProduct(printRequestQuery.data?.userPrintRequests)}</b>
                  </div> 
                  : null
                }
              </div>
            </div>
            <div className='flex absolute right-0 gap-2'>
              <ProductDelete productId={product?.productId} callback={goBackToAllProducts}/>
              { editing ? 
                <div className='flex gap-0'>
                  <Button variant="minimal" disabled={updating} size="icon" onClick={() => toggleEditProduct(true)}>
                    <X className='h-4 w-4' />
                  </Button>
                  <Button variant="bwconfirm" disabled={updating} size="icon" onClick={() => toggleEditProduct()}>
                    { updating ? <Spin className="inline-spin h-4 w-4" color="#ffffff" width="20px" height="20px" duration="0.3s" /> : <Save className='h-4 w-4' />}
                  </Button>
                </div>
                : 
                <Button variant="bwsecondary" disabled={user?.userId !== product?.createdBy?.userId} size="icon" onClick={() => toggleEditProduct()}>
                  <Pencil className='h-4 w-4' />
                </Button>
              }
              {product?.productId ? <MessagesPopover productId={product?.productId} /> : null}
              {product?.status === 'approved' ? <PrintProduct buttonTitle='Print' product={product} gcodeFiles={files.filter(helpers.filters.isFileGcode)} /> : null}
              {product?.status === 'production' ? <PrintProduct buttonTitle='Produce' product={product} gcodeFiles={files.filter(helpers.filters.isFileGcode)} /> : null}
            </div>
          </div>
          <div className='product-details-scroll-container'>
            {editing && editedProduct ? 
              <div className='product-details-description edit mb-4'>
                <Textarea rows={1} className='text-md' name='productDescription' placeholder="Product description" value={editedProduct.description} onChange={(event) => updateProductDescription(event.target.value)} />
              </div>
              :
              <div className='product-details-description mb-4 text-md'>
                {product?.description}
              </div>
            }
{/* 
            { !files.filter(helpers.filters.isFileGcode)?.length ? <CustomFileUpload onFilesChange={handleFilesChange} key={reloadKey} small type='gcode'/> : null} */}

            <Collapsible
              open={printRequestsOpen}
              onOpenChange={setPrintRequestsOpen}
              className={filterActivePrintRequestsForProduct(printRequestQuery.data?.userPrintRequests)?.length > 0 ? "w-[100%] space-y-2 mb-4 " : "hidden w-[100%] space-y-2 mb-4"}
            >
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
                  <div>
                    <ChevronRight className={`h-4 w-4 transition-all ease-out-expo duration-150 ${printRequestsOpen && 'rotate-90'}`}/>
                    <span className="sr-only">Toggle</span>
                  </div>
                  <h4 className="text-sm font-semibold">
                    Print requests <span className='text-bw-green/30 font-normal'>({filterActivePrintRequestsForProduct(printRequestQuery.data?.userPrintRequests)?.length})</span>
                  </h4>
                  <Separator className='flex-1 grow border-b relative top-[1px] border-bw-grey ml-1'/>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-2">
                <div className='flex flex-col gap-4'>
                  {filterActivePrintRequestsForProduct(printRequestQuery?.data?.userPrintRequests)?.map((request: PrintRequest, index: number) => (
                    <div className={request.interupted ? 'flex flex-col gap-1 opacity-30' : 'flex flex-col gap-1'}>
                      <ProgressBar bright height={4} progress={(Number(request.jobsCompleted) / Number(request.quantity) * 100)} />
                      <div className='flex justify-between gap-2 text-sm items-center'>
                        <div>
                          <div className='flex gap-1 grow'>
                            <div>{request.jobsCompleted}/{request.quantity}</div>
                            <div className='font-bold truncate max-w-[70%]'>{request.file.fileName}</div>
                          </div>
                          <div className='print-request-details flex gap-2 relative text-bw-green/30'>
                            <div className='text-xs'>Location: <b>{request.factory?.name}</b> - </div>
                            <div className='text-xs'>Requested: {helpers.formatDateToDDMMYY(request.issuedAt)}</div>
                          </div>
                        </div>
                        <div className='text-right'>
                          <Button variant='minimal' size='smallicon' onClick={() => handleDeletePrintRequest(request.requestId)}>
                            <XCircle className='h-4 w-4'></XCircle>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CollapsibleContent>
            </Collapsible>

            <Collapsible
              open={impactDataOpen}
              onOpenChange={setImpactDataOpen}
              className={`space-y-2 mb-4`}
            >
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
                  <div>
                    <ChevronRight className={`h-4 w-4 transition-all ease-out-expo duration-150 ${impactDataOpen && 'rotate-90'}`}/>
                    <span className="sr-only">Toggle</span>
                  </div>
                  <h4 className="text-sm font-semibold">
                    Impact Data
                  </h4>
                  <Separator className='flex-1 grow border-b relative top-[1px] border-bw-grey ml-1'/>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-2">
                <div className=''>
                  <EmissionsData files={files} />
                </div>
              </CollapsibleContent>
            </Collapsible>

            <Collapsible
              open={gcodeFilesOpen}
              onOpenChange={setGcodeFilesOpen}
              className="w-[100%] space-y-2  mb-4"
            >
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
                  <div>
                    <ChevronRight className={`h-4 w-4 transition-all ease-out-expo duration-150 ${gcodeFilesOpen && 'rotate-90'}`}/>
                    <span className="sr-only">Toggle</span>
                  </div>
                  <h4 className="text-sm font-semibold flex gap-1">
                    Print File 
                    {/* <span className='text-bw-green/30 font-normal'>{files.filter(helpers.filters.isFileGcode)?.length ? '(' + files.filter(helpers.filters.isFileGcode)?.length + ')' : 'No Files'}</span> */}
                  </h4>
                  <Separator className='flex-1 grow border-b relative top-[1px] border-bw-grey ml-1'/>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-2">
                {files.filter(helpers.filters.isFileGcode)?.map((file: any, index: number) => {
                  return <div className='flex flex-col gap-1'>
                    <GcodeFileInfo product={product} updating={isSlicing} file={file} key={index} index={index} showActions={true} user={user} showDetails slicedExternally={slicedExternally} slicerSettingsOpen={slicerSettingsOpen} callback={() => setSlicerSettingsOpen(!slicerSettingsOpen)}/>
                    {slicerParameters &&
                      <SlicerSettings show={slicerSettingsOpen} settings={slicerParameters} isSlicing={isSlicing} resliceWith={(params: SlicerParameters) => resliceWith(params, file.fileId)}/>
                    }
                  </div>
                })}
                {/* {files.filter(helpers.filters.isFileGcode)?.length ? <CustomFileUpload onFilesChange={handleFilesChange} key={reloadKey} small type='gcode' /> : null } */}
              </CollapsibleContent>
            </Collapsible>

            <Collapsible
              open={stlFilesOpen}
              onOpenChange={setStlFilesOpen}
              className="w-[100%] space-y-2  mb-4"
            >
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
                  <div>
                    <ChevronRight className={`h-4 w-4 transition-all ease-out-expo duration-150 ${stlFilesOpen && 'rotate-90'}`}/>
                    <span className="sr-only">Toggle</span>
                  </div>
                  <h4 className="text-sm font-semibold">
                    STL <span className='text-bw-green/30 font-normal'>{files.filter(helpers.filters.isFileStl)?.length ? '(' + files.filter(helpers.filters.isFileStl)?.length + ')' : 'No Files'}</span>
                  </h4>
                  <Separator className='flex-1 grow border-b relative top-[1px] border-bw-grey ml-1'/>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-2">
                {files.filter(helpers.filters.isFileStl)?.map((file: any, index: number) => {
                  return <div className='file-preview flex justify-between items-center rounded-md border border-bw-pale-sage bg-white px-4 py-3 font-mono text-sm hover:border-transparent' key={index}>
                    {file.fileName}
                    <DownloadButton url={file.presignedUrl} name={file.fileName}/>
                  </div>
                })}
              </CollapsibleContent>
            </Collapsible>
            <Collapsible
              open={otherFilesOpen}
              onOpenChange={setOtherFilesOpen}
              className="w-[100%] space-y-2  mb-4"
            >
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
                  <div>
                    <ChevronRight className={`h-4 w-4 transition-all ease-out-expo duration-150 ${otherFilesOpen && 'rotate-90'}`}/>
                    <span className="sr-only">Toggle</span>
                  </div>
                  <h4 className="text-sm font-semibold">
                    Other <span className='text-bw-green/30 font-normal'>{files.filter(helpers.filters.isNotGcodeNorStl)?.length ? '(' + files.filter(helpers.filters.isNotGcodeNorStl)?.length + ')' : 'No Files'} </span>
                  </h4>
                  <Separator className='flex-1 grow border-b relative top-[1px] border-bw-grey ml-1'/>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-2">
                {files.filter(helpers.filters.isNotGcodeNorStl)?.map((file: any, index: number) => {
                  return <div className='file-preview flex justify-between items-center rounded-md border border-bw-pale-sage bg-white px-4 py-3 font-mono text-sm hover:border-transparent' 
                              key={index}
                              onClick={() => helpers.extractFileTypeFromFilename(file.fileName).toLowerCase() === 'jpg' || 'png' || 'jpeg' ? setImageOpen(file.presignedUrl) : null}>
                    {file.fileName}
                    <DownloadButton url={file.presignedUrl} name={file.fileName}/>
                  </div>
                })}
                {files.filter(helpers.filters.isNotGcodeNorStl)?.length === 0 ? <div className='text-xs text-bw-green/30'>No other files have been uploaded yet. Simply drop them below and update after.</div> : null}
                <CustomFileUpload onFilesChange={handleFilesChange} key={reloadKey} small type='anything' />
              </CollapsibleContent>
            </Collapsible>

            {product?.preferences ? 
              <Collapsible
                open={perferencesOpen}
                onOpenChange={setPreferencesOpen}
                className="w-[100%] space-y-2"
              >
                <CollapsibleTrigger asChild>
                  <div className="flex items-center justify-start gap-1 collapsible-trigger pt-1 pb-1">
                    <div>
                      {perferencesOpen ? <ChevronDown className='h-4 w-4'/> : <ChevronRight className='h-4 w-4'/>}
                      <span className="sr-only">Toggle</span>
                    </div>
                    <h4 className="text-sm font-semibold">
                      Preferences
                    </h4>
                    <Separator className='flex-1 grow border-b relative top-[1px] border-bw-grey ml-1'/>
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent className="space-y-2 flex flex-col gap-2">
                  <div className='flex justify-between gap-20'>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Layer height:</div>
                      <div className='font-bold'>{product.preferences.layerHeight} mm</div>
                    </div>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Filament:</div>
                      <div className='font-bold flex items-center gap-1'><Filament color={product.preferences.filament?.colour.hexCode || '#333333'} active={true} med /> {product.preferences.filament?.material.displayName} - {product.preferences.filament?.colour.displayName}</div>
                    </div>
                  </div>

                  <div className='flex justify-between gap-20'>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Layer width:</div>
                      <div className='font-bold'>{product.preferences.layerWidth} mm</div>
                    </div>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Vase mode:</div>
                      <div className='font-bold flex items-center gap-1'>{product.preferences.vaseMode ? <Check className='w-4 h-4'/> : <X className='w-4 h-4'/>}</div>
                    </div>
                  </div>

                  <div className='flex justify-between gap-20'>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Nozzle size:</div>
                      <div className='font-bold'>{product.preferences.nozzleSize} mm</div>
                    </div>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Vase mode Base:</div>
                      <div className='font-bold flex items-center gap-1'>{product.preferences.vaseModeBase ? <Check className='w-4 h-4'/> : <X className='w-4 h-4'/>}</div>
                    </div>
                  </div>

                  <div className='flex justify-between gap-20'>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">Infill:</div>
                      <div className='font-bold'>{product.preferences.infill} %</div>
                    </div>
                    <div className='text-sm text-bw-green flex flex-1 justify-between'>
                      <div className="opacity-30">In depth review:</div>
                      <div className='font-bold flex items-center gap-1'>{product.preferences.indepthPreview ? <Check className='w-4 h-4'/> : <X className='w-4 h-4'/>}</div>
                    </div>
                  </div>
                </CollapsibleContent>
              </Collapsible>
            : null }
          </div>
        </div>
  )
}

export default ProductDetails