import * as THREE from 'three';
import { Model, Layer, ExtruderPoint, ModelBounds } from "@/global-components/types"

export const parseGcode = (gcode: string, nozzleDiameter: number | undefined, filamentDiameter: number): Model | null => {
  let gCodeModel: Model;

  const relativeExtrusion: boolean = gcode.split('\n').find(line => line.startsWith('M83')) ? true : false;
  const gCodeRawLines: string[] | undefined = gcode.split('\n')
    .filter((gCodeLine: string) => {
      if (gCodeLine.toLowerCase().startsWith('; layer') || gCodeLine.toLowerCase().startsWith(';layer')) {
        return true
      } else if (gCodeLine.startsWith('G1')) {
        return true
      } else {
        return false
      }
    })

    if (!gCodeRawLines.length) {
      console.log('Input faulty or not understood.')
      return null
    }

    const gCodeRawLayers: string[][] = splitGcodeIntoLayers(gCodeRawLines)

    const layers: Layer[] = gCodeRawLayers.map((gCodeRawLayer: string[], index: number) => {
      const isVaseMode: boolean = isLayerVaseMode(gCodeRawLayer)
      const extruderPoints: ExtruderPoint[] = gCodeToExtruderPoints(gCodeRawLayer.slice(1))
      const printingExtruderPoints: ExtruderPoint[] = extruderPoints.filter(point => point.e > 0)
      return {
        number: getLayerNumberFromString(gCodeRawLayer[0]),
        extruderPoints: extruderPoints,
        printingExtruderPoints: printingExtruderPoints,
        vaseMode: isVaseMode,
        layerHeight: 0,
        extrusionWidth: 0,
        originalStringForLayerNumber: gCodeRawLayer[0],
      }
    }).filter(removeRedundantLayer)

  

    const updatedLayers: Layer[] = replaceNegativeNumbersWithLastSeen([...layers])
    const modelRange: ModelBounds = getModelBounds(updatedLayers)
    
    gCodeModel = {
      layers: updatedLayers,
      relativeExtrusion: relativeExtrusion,
      modelBounds: modelRange,
      center: getModelCenter(modelRange),
    }

    return gCodeModel
}


const getModelBounds = (layers: Layer[]): ModelBounds => {
  let range: ModelBounds = {
    x: {
      min: Infinity,
      max: Infinity,
    },
    y: {
      min: Infinity,
      max: Infinity,
    },
    z: {
      min: Infinity,
      max: Infinity,
    }
  }

  for (let layer of layers) {
    for (let extruderPoint of layer.extruderPoints) {
      if(extruderPoint.x !== undefined  && extruderPoint.y !== undefined) {
        if (range.x.min === Infinity || range.x.min > extruderPoint.x) {
          range.x.min = extruderPoint.x;
        } else if (range.x.max === Infinity || range.x.max < extruderPoint.x) {
          range.x.max = extruderPoint.x;
        }

        if (range.y.min === Infinity || range.y.min > extruderPoint.y) {
          range.y.min = extruderPoint.y;
        } else if (range.y.max === Infinity || range.y.max < extruderPoint.y) {
          range.y.max = extruderPoint.y;
        }

        if (range.z.min === Infinity || range.z.min > extruderPoint.z) {
          range.z.min = extruderPoint.z;
        } else if (range.z.max === Infinity || range.z.max < extruderPoint.z) {
          range.z.max = extruderPoint.z;
        }
      }
    }
  }

  return range;
}

const getModelCenter = (modelBounds: ModelBounds): THREE.Vector3 => {  
  return new THREE.Vector3((modelBounds.x.min + modelBounds.x.max) / 2, (modelBounds.y.min + modelBounds.y.max) / 2, 0)
}

const replaceNegativeNumbersWithLastSeen = (layers: Layer[]): Layer[] => {
  let xLast: number | undefined, yLast: number | undefined, zLast: number | undefined, 
      eLast: number | undefined, feedRateLast: number | undefined;

  for (let layer of layers) {
    for (let extruderPoint of layer.extruderPoints) {
      if (extruderPoint.x !== -1) {
        xLast = extruderPoint.x;
      } else if (extruderPoint.x === -1 && xLast !== undefined) {
        extruderPoint.x = xLast;
      }

      if (extruderPoint.y !== -1) {
        yLast = extruderPoint.y;
      } else if (extruderPoint.y === -1 && yLast !== undefined) {
        extruderPoint.y = yLast;
      }

      if (extruderPoint.z !== -1) {
        zLast = extruderPoint.z;
      } else if (extruderPoint.z === -1 && zLast !== undefined) {
        extruderPoint.z = zLast;
      }

      // if (extruderPoint.e !== -1) {
      //   eLast = extruderPoint.e;
      // } else if (extruderPoint.e === -1 && eLast !== undefined) {
      //   extruderPoint.e = eLast;
      // }

      if (extruderPoint.feedRate !== -1) {
        feedRateLast = extruderPoint.feedRate;
        extruderPoint.feedRateChanged = true;
      } else if (extruderPoint.feedRate === -1 && feedRateLast !== undefined) {
        extruderPoint.feedRate = feedRateLast;
        extruderPoint.feedRateChanged = false;
      }
    }
  }

  return layers;
};

const isLayerVaseMode = (rawLayer: string[]): boolean => {
  const movements: number = rawLayer.length
  const movementsWithZ: number = rawLayer.filter((command: string) => 
    command.toLowerCase().startsWith('g') && command.toLowerCase().includes('z')).length
  
  return movementsWithZ / movements > 0.8
}

const splitGcodeIntoLayers = (gCodeLines: string[]): string[][] => {
  const gCodeLayers: string[][] = [];
  let currentLayer: string[] = [];
  gCodeLines.forEach((gCodeLine: string, index: number) => {
    if (gCodeLine.toLowerCase().startsWith('; layer') || gCodeLine.toLowerCase().startsWith(';layer')) {
      if (currentLayer.length || index === gCodeLines.length - 1) {
        gCodeLayers.push(currentLayer);
        currentLayer = [];
      }
      currentLayer = [gCodeLine];
    } else {
      currentLayer.push(gCodeLine);
    }
  });

  return gCodeLayers;
}

const getLayerNumberFromString = (string: string): number => {
  const match = string.match(/; layer (\d+)/);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    return -1;
  }
}

const gCodeToExtruderPoints = (gCodeLines: string[]): ExtruderPoint[] => {
  const extrusionPoints = gCodeLines
    .map((gCodeTextLine: string, index: number) => {
      const coordinates: string[] = gCodeTextLine.split(' ');
      const xCoord: number = parseFloat(
        coordinates.find((coordinate:string) => coordinate.toLowerCase().startsWith('x'))?.substring(1) || '-1'
      )
      const yCoord: number = parseFloat(
        coordinates.find((coordinate:string) => coordinate.toLowerCase().startsWith('y'))?.substring(1) || '-1'
      )
      const zCoord: number = parseFloat(
        coordinates.find((coordinate:string) => coordinate.toLowerCase().startsWith('z'))?.substring(1) || '-1'
      )
      const eValue: number = parseFloat(
        coordinates.find((coordinate:string) => coordinate.toLowerCase().startsWith('e'))?.substring(1) || '-1'
      )
      const feedRate: number = parseFloat(
        coordinates.find((coordinate:string) => coordinate.toLowerCase().startsWith('f'))?.substring(1) || '-1'
      )
      const extruderPoint: ExtruderPoint = {
        x: xCoord,
        y: yCoord,
        z: zCoord,
        e: eValue,
        feedRate: feedRate,
        feedRateChanged: false,
        originalString: gCodeTextLine
      } 

      return extruderPoint
    })
    // .filter(removeRedundantPoint)

  return extrusionPoints
}

const removeRedundantLayer = (layer: Layer): boolean => {
  if (layer.originalStringForLayerNumber?.toLowerCase().includes('end')) {
    return false
  }
  return true
}