import { useState } from 'react'
import { FileType } from '../types';

const debug: boolean = process.env.REACT_APP_BRANCH === 'beta' ? false : true;

/*const log = (message: any) => {
  if (debug) {
    console.trace(message);
  }
};*/

const log = debug ? console.log.bind(window.console) : () => {};



const dateAndTime = (datetime: string): string => {
  const date = new Date(datetime);
  return date.toLocaleString();
}

const formatDateToDDMMYY = (dateObject: Date | string | undefined, justDate?: boolean) => {
  if (dateObject === undefined) {
    return 'n/a'
  } else if (!(dateObject instanceof Date)) {
    dateObject = new Date(dateObject);
  }
  // Date components
  let day = dateObject.getDate().toString().padStart(2, '0');
  let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  let year = dateObject.getFullYear().toString().slice(2);

  // Time components
  let hours = dateObject.getHours().toString().padStart(2, '0');
  let minutes = dateObject.getMinutes().toString().padStart(2, '0');
  let seconds = dateObject.getSeconds().toString().padStart(2, '0');

  if (!day || day === 'NaN') {
    return '';
  } else if(justDate) {
    return `${day}.${month}.${year}`;
  } else {
    return `${day}.${month}.${year} at ${hours}:${minutes}:${seconds}`;
  }
  
}

const extractFileTypeFromFilename = (filename: string): string => {
  const splitName = filename.split(".");
  return splitName.length > 1 ? splitName[splitName.length - 1].toLowerCase() : '';
}

const extractFileTypeFromUrl = (url: string): string => {
  const urlObject = new URL(url);
  return extractFileTypeFromFilename(urlObject.pathname);
}

const isFileGcode = (file: FileType | File): boolean => {
  return extractFileTypeFromFilename(file instanceof File ? file.name : file.fileName) === 'gcode'
}

const isFileStl = (file: FileType | File): boolean => {
  return extractFileTypeFromFilename(file instanceof File ? file.name : file.fileName) === 'stl'
}

const isNotGcodeNorStl = (file: FileType | File): boolean => {
  return extractFileTypeFromFilename(file instanceof File ? file.name : file.fileName) !== 'stl'
    && extractFileTypeFromFilename(file instanceof File ? file.name : file.fileName) !== 'gcode'
}

const isEmailValid = (_email: string): boolean => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(_email) ? true : false;
}


const setLocalStorageItem = (key: string, value: string): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
};

const getLocalStorageItem = (key: string): string | null => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return null;
  }
};

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

const removeUnusedFilesFromLocalStorage = () => {
  const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
  const now = Date.now();

  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('file_lastUsed_')) {
      const lastUsedTime: string | null = localStorage.getItem(key)
      if (lastUsedTime) {
        if (now - parseInt(lastUsedTime) > threeDaysInMillis) {
          const fileId = key.replace('file_lastUsed_', '');
          localStorage.removeItem(`file_${fileId}`);
          localStorage.removeItem(`file_lastUsed_${fileId}`);
        }
      }
    }
  });
};

const helpers = {
  dateAndTime,
  formatDateToDDMMYY,
  extractFileTypeFromUrl,
  extractFileTypeFromFilename,
  setLocalStorageItem,
  getLocalStorageItem,
  openInNewTab,
  log,
  isEmailValid,
  removeUnusedFilesFromLocalStorage,
  filters: {
    isFileGcode,
    isFileStl,
    isNotGcodeNorStl
  }
}

export default helpers;