import * as THREE from 'three'
import { ExtruderPoint, Model, Layer } from '@/global-components/types';

export const createChamferedRectShape = (width:number, height:number, chamferSize:number) => {
  const shape = new THREE.Shape();

  // Ensure chamfer cannot exceed dimensions
  chamferSize = Math.min(chamferSize, width / 2, height / 2);

  shape.moveTo(chamferSize, 0);
  shape.lineTo(width - chamferSize, 0);
  shape.lineTo(width, chamferSize);
  shape.lineTo(width, height - chamferSize);
  shape.lineTo(width - chamferSize, height);
  shape.lineTo(chamferSize, height);
  shape.lineTo(0, height - chamferSize);
  shape.lineTo(0, chamferSize);
  shape.lineTo(chamferSize, 0);

  return shape;
}

export const createRoundedRectShape = (width: number, height: number, radius: number) => {
  const shape = new THREE.Shape();

  // Ensure radius cannot exceed dimensions
  radius = Math.min(radius, width / 2, height / 2);

  // Start at the top left corner (with the radius offset)
  shape.moveTo(radius, 0);

  // Top right corner
  shape.lineTo(width - radius, 0);
  shape.quadraticCurveTo(width, 0, width, radius);

  // Bottom right corner
  shape.lineTo(width, height - radius);
  shape.quadraticCurveTo(width, height, width - radius, height); 

  // Bottom left corner
  shape.lineTo(radius, height);
  shape.quadraticCurveTo(0, height, 0, height - radius);

  // Top left corner
  shape.lineTo(0, radius);
  shape.quadraticCurveTo(0, 0, radius, 0);

  return shape;
};

export const vectorsFromPoints = (points: ExtruderPoint[]): THREE.Vector3[] => {
  const vectors: THREE.Vector3[] = []
  points.forEach(point => {
    vectors.push(new THREE.Vector3(point.x, point.y, point.z))
  })
  return vectors
}

export const modelToThreeLines = (_model: Model | null): THREE.Vector3[][] => {
  if (!_model) {
    return []
  }
  return _model.layers.map((layer: Layer) => {
    return layer.extruderPoints.map((extruderPoint: ExtruderPoint) => {
      const threePoint: THREE.Vector3 = new THREE.Vector3(extruderPoint.x, extruderPoint.y, extruderPoint.z)
      return threePoint;
    })
  })
}

export function removeSubdivisionsFromStraightLinesWithDot(vectorArray: THREE.Vector3[], angleThresholdRadians = 0.002) {
  if (vectorArray.length < 3) return vectorArray;

  const optimizedArray = [vectorArray[0]];

  for (let i = 1; i < vectorArray.length - 1; i++) {
      const p1 = vectorArray[i - 1];
      const p2 = vectorArray[i];
      const p3 = vectorArray[i + 1];

      const v1 = p2.clone().sub(p1).normalize();
      const v2 = p3.clone().sub(p2).normalize();

      // Calculate the dot product of v1 and v2
      const dotProduct = v1.dot(v2);

      // Calculate the angle in radians between v1 and v2 using the dot product
      // Note: For normalized vectors, dot product = cos(angle)
      // If the dotProduct is close to 1, the angle is close to 0 (collinear and pointing in the same direction)
      // If the dotProduct is close to -1, the angle is close to π (collinear but pointing in opposite directions)
      // We use a threshold around these values to allow for minor deviations
      if (!(dotProduct > 1 - angleThresholdRadians || dotProduct < -1 + angleThresholdRadians)) {
          optimizedArray.push(p2);
      }
  }

  optimizedArray.push(vectorArray[vectorArray.length - 1]);

  return optimizedArray;
}

export const getAverageExtrusionWidthForVaseLayer = (extruderPoints: ExtruderPoint[], filamentDiameter: number, layerHeight: number, relativeExtrusion: boolean): number => {
  const allWidths: number[] = [];
  extruderPoints.filter(point => point.e > 0)
    .forEach((point: ExtruderPoint, index: number) => {
    if (index > 0) {
      const movementLength: number = Math.sqrt( (extruderPoints[index - 1].x - point.x) ** 2 + (extruderPoints[index - 1].y - point.y) ** 2)
      const filamentCrossSectionArea: number = Math.PI * (filamentDiameter / 2 ) ** 2 
      const extrusionLength: number = relativeExtrusion ? point.e : (point.e - extruderPoints[index - 1].e) 
      if (extrusionLength > 0 && movementLength > 0 && extrusionLength < 1) {
        // console.log('EXTRUSION LENGTH: ' + extrusionLength + '  | LAYER HEIGHT: ' + layerHeight + '  | MOVEMENT: ' + movementLength)
        const extrudedVolume: number = extrusionLength * filamentCrossSectionArea
        const extrusion_width: number = extrudedVolume / (layerHeight * movementLength)
        allWidths.push(extrusion_width)
      }
    }
  })
  const average: number = allWidths.reduce((a, b) => a + b, 0) / allWidths.length
  return average
}


export const getLayerHeight = (extruderPoints: ExtruderPoint[], prevExtruderPoints: ExtruderPoint[] | null, vaseMode: boolean, realisticThreshold: number): number => {
  if (vaseMode) {
    const zStart: number | undefined = extruderPoints.find((point: ExtruderPoint) => point.z > 0)?.z
    const zEnd: number | undefined = [...extruderPoints].reverse().find((point: ExtruderPoint) => point.z > 0)?.z
    if (zStart && zEnd) {
      const layerHeight: number = zEnd - zStart;
      return layerHeight <= realisticThreshold ? layerHeight : -1
    } else {
      return -1
    }
  } else {
    if (prevExtruderPoints === null) {
      const z: number | undefined = extruderPoints[Math.round(extruderPoints.length / 2)]?.z
      if (z===undefined) {
        return -1
      } else {
        return z
      }
    } else {
      const z: number | undefined = extruderPoints[Math.round(extruderPoints.length / 2)]?.z
      const zLast: number | undefined = prevExtruderPoints[Math.round(prevExtruderPoints.length / 2)]?.z
      if (z===undefined || zLast===undefined) {
        return -1
      } else {
        return z - zLast
      }
    }
  }
}

export const calculateExtrusionWidth = (point: ExtruderPoint, prevPoint: ExtruderPoint, layerHeight: number, relativeExtrusion: boolean, filamentDiameter:number = 1.75): number => {
  if (point.e < 0 || prevPoint.e < 0) {
    return 0
  }
  const movementLength: number = Math.sqrt( (point.x - prevPoint.x) ** 2 + (point.y - prevPoint.y) ** 2)
  const filamentCrossSectionArea: number = Math.PI * (filamentDiameter / 2 ) ** 2 
  const extrusionLength: number = relativeExtrusion ? point.e : point.e - prevPoint.e
  const extrudedVolume: number = extrusionLength * filamentCrossSectionArea
  const extrusion_width: number = extrudedVolume / (layerHeight * movementLength)

  if (extrusion_width > 200) {
    return 0
  }
  if (extrusion_width < 0) {
    return 0
  }
  return extrusion_width; 
}


export const calculateOffsetPoint = (point1: ExtruderPoint, point2: ExtruderPoint, point3: ExtruderPoint, point4: ExtruderPoint): THREE.Vector3 => {
  // Convert points to THREE.Vector3 if they're not already
  let p1 = new THREE.Vector3(point1.x, point1.y, point1.z);
  let p2 = new THREE.Vector3(point2.x, point2.y, point2.z);
  let p3 = new THREE.Vector3(point3.x, point3.y, point3.z);
  let p4 = new THREE.Vector3(point4.x, point4.y, point4.z);

  // Calculate direction vectors
  let dir1 = new THREE.Vector3().subVectors(p2, p1).normalize(); // Direction of Segment 1
  let dir2 = new THREE.Vector3().subVectors(p3, p2).normalize(); // Direction of Segment 2
  let dir3 = new THREE.Vector3().subVectors(p4, p3).normalize(); // Direction of Segment 3

  // Calculate angles between segments
  let angle1 = dir1.angleTo(dir2); // Angle between Segment 1 and 2
  let angle2 = dir2.angleTo(dir3); // Angle between Segment 2 and 3

  // Calculate offset direction - This example assumes planar geometry for simplicity
  // For 3D, you might need a more complex approach, perhaps using cross products
  let offsetDirection = new THREE.Vector3(-dir2.y, dir2.x, dir2.z).normalize();

  // Determine offset magnitude based on angles - Simplified example logic
  let offsetMagnitude = Math.min(angle1, angle2) / -6.5; // Adjust this logic as needed

  // Calculate midpoint of Segment 2
  let midpoint = new THREE.Vector3().lerpVectors(p2, p3, 0.5);

  // Apply offset to midpoint to create a new offset point
  let offsetPoint = new THREE.Vector3().addVectors(midpoint, offsetDirection.multiplyScalar(offsetMagnitude));

  // Return the calculated offset point
  return offsetPoint;
}
