import React, { useState, useRef, MouseEvent, useEffect } from 'react';
import { Paperclip, Layers, Info, X, XCircle, Box, Mouse } from "lucide-react";
import { Button } from '@/global-components/components/ui/button'
import './CustomFileUpload.scss';

interface CustomFileUploadProps {
  onFilesChange: (files: File[]) => void;
  small?: Boolean;
  type?: string;
}

const CustomFileUpload: React.FC<CustomFileUploadProps> = ({ onFilesChange, small, type }) => {
  const [files, setFiles] = useState<File[]>([])
  const [stlCount, setStlCount] = useState<number>(0)
  const [gcodeCount, setGcodeCount] = useState<number>(0)
  const [dragging, setDragging] = useState(false)
  const [productSubmissionCallToAction, setProductSubmissionCallToAction] = useState<string>('Drop Your Design File')
  const fileInputRef = useRef<HTMLInputElement>(null);

  const updateFiles = (newFiles: File[], fileToRemove?: File) => {
    console.log('removing file: ', fileToRemove)
    const updatedFiles = fileToRemove !== undefined ? files.filter(file => file !== fileToRemove) : [...files, ...newFiles];
    const _stlCount: number = updatedFiles.filter(file => file.name.toLowerCase().endsWith('.stl')).length
    const _gcodeCount: number = updatedFiles.filter(file => file.name.toLowerCase().endsWith('.gcode')).length
    setStlCount(_stlCount)
    setGcodeCount(_gcodeCount)
    console.log('stl count: ', _stlCount)
    console.log('gcode count: ', _gcodeCount  )
    if (type === 'productsubmission' && (_stlCount > 1 || _gcodeCount > 1)) {
      return
    } 
    setFiles(updatedFiles);
  }

  const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    updateFiles(newFiles)
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation(); 
    setDragging(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation(); 
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setDragging(false);
    e.preventDefault();
    e.stopPropagation();
    const newFiles = Array.from(e.dataTransfer.files);
    const uniqueNewFiles = newFiles.filter(newFile => 
      !files.some(file => file.name === newFile.name)
    );
    
    updateFiles(uniqueNewFiles)
    

    if (fileInputRef.current) {
      const dt = new DataTransfer();
      for (const file of newFiles) {
        dt.items.add(file);
      }
      fileInputRef.current.files = dt.files;
    }
  };

  const getIcon = (type: string | undefined): JSX.Element => {
    switch(type) {
      case 'anything':
        return <Paperclip className='h-4 w-4' />
        break;
      case 'productsubmission':
        return <Box className={`h-4 w-4 animate-bounce`} />
        break;
      case 'gcode':
        return <Layers className='h-4 w-4' />
        break;
      default:
        return <Paperclip className='h-4 w-4' />
        break;
    }
  }

  const getText = (type: string | undefined): JSX.Element => {
    switch(type) {
      case 'anything':
        return <span className='small type'>
          Add any file
        </span>
        break;
      case 'productsubmission':
        return <span className='text-sm text-center'>
          {productSubmissionCallToAction}
          <span className='small type text-center flex gap-1 items-center opacity-30 italic'>
            (you can add GCode if you have it)
          </span>
        </span>
        break;
      case 'gcode':
        return <span className='small type'>
          Add GCode
        </span>
        break;
      default:
        return <span className='small type'>
          Add any file
        </span>
        break;
    }
  }

  const handleUploaderClick = (event: MouseEvent<HTMLDivElement>) => {
    if (fileInputRef.current && event.currentTarget === event.target) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    onFilesChange(files)
  }, [files])

  return (
    <div className={`custom-file-upload z-40 relative bg-bw-pale-sage/30 ${small && 'small'}`}>
      <div className='input-and-label'>
        <div>
          <div className={`file-upload-drop-area border border-bw-grey ${dragging && 'dragging border-bw-grey-focus'} ${(stlCount > 1 || gcodeCount > 1) && files.length === 0 ? 'border-ui-denial-red' : ''}`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleUploaderClick}
          >
            {(stlCount > 1 || gcodeCount > 1) &&
              <div className='absolute right-2 top-2 text-xs text-white bg-ui-denial-red rounded-full px-2 py-1 animate-delayed-fade-out'>
                You can only add one Design File per Product
              </div>
            }
            <div className={files.length === 0 ? 'drop-file-icon' : 'drop-file-icon hidden'}>
              {getIcon(type)}
              {getText(type)}
            </div>
            <ul className={files.length > 0 ? '' : 'empty'}>
              {files.map((file, index) => (
                <li key={index}>
                  <span>{file.name}</span>
                  <Button size='xs' className='z-20 font-bold' variant='destructiveminimal' onClick={(e) => {
                  e.preventDefault();
                  updateFiles([], file)
                  }}>
                    <XCircle className='h-4 w-4'></XCircle>
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          <input ref={fileInputRef} type="file" name='gcode_file' id="gcode_file" multiple accept="*" onChange={handleFiles} className='default bright hidden'></input>
        </div>
      </div>
    </div>
  );
}

export default CustomFileUpload;
