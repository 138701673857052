import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import { Organisation, OrganisationMembership, User, UserName } from '@/global-components/types'
import { Input } from '@/global-components/components/ui/input'

import helpers from '@/global-components/components/helpers';

import { useMutation, useQuery } from '@apollo/client';
import api from '@/api/bw-api'
import { Button } from '@/global-components/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/global-components/components/ui/tooltip"
import { Spin } from 'react-cssfx-loading';
import { Building, Cookie, PlusCircle, XCircle } from 'lucide-react';
import { Label, Separator } from '@radix-ui/react-select';
import { useToast } from '@/global-components/components/ui/use-toast';

const Account = () => {
  const { toast } = useToast();
  const { user, setUser } = useAuth();

  const [active, setActive] = useState<string>('profile')

  const organisationsQuery = useQuery(api.organisations.queries.GET_ORGANISATIONS);
  const [organisations, setOrganisations] = useState<Organisation[]>([])
  const [userOwnedOrganisations, setUserOwnedOrganisations] = useState<Organisation[]>([])

  const [searchUserOpen, setSearchUserOpen] = useState<boolean>(false)
  const [userSearchTerm, setUserSearchTerm] = useState<string>('')
  const [addingUserToOrganisation, setAddingUserToOrganisation] = useState<Organisation | null>(null)
  const usersSearchQuery = useQuery(api.user.queries.USERNAMES, {
    variables: {
      username: userSearchTerm
    }
  })
  const [foundUsers, setFoundUsers] = useState<UserName[]>([])

  const [username, setUsername] = useState<string>(user ? user.username : '')
  const [email, setEmail] = useState<string>(user ? user.email : '')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>(user ? user.firstName : '')
  const [lastName, setLastName] = useState<string>(user ? user.lastName : '')

  const [newOrgName, setNewOrgName] = useState<string>('')
  const [newOrgContactEmail, setNewOrgContactEmail] = useState<string>('')

  const [updatingAccount, setUpdatingAccount] = useState<boolean>(false)

  const [updateMutation] = useMutation(api.user.mutations.UPDATE_MY_ACCOUNT)

  const updateAccount = () => {
    if (!helpers.isEmailValid(email)) {
      setEmailError(true)
      return
    }

    setEmailError(false)
    setUpdatingAccount(true)
    updateMutation({variables: {firstName: firstName, lastName: lastName, email: email.toLowerCase(), username: username}})
      .then((result) => {
        if(result.data.updateAccount.success) {
          toast({
            title: "Your account data has been updated.",
            variant: "success",
            duration: 3000
          })
        }
      })
      .catch(error => {
        helpers.log(error)
        setUpdatingAccount(false)
        toast({
          title: "There was an error updating your account. Please try again later",
          variant: "destructive",
          duration: 3000
        })
      })
      .finally(() => {
        setUpdatingAccount(false)
      })
  }

  const handleSearchUserOpen = (open: boolean, org: Organisation) => {
    setSearchUserOpen(open)
    if (!open) {
      setUserSearchTerm('')
    } else {
      setAddingUserToOrganisation(org)
    }
  }

  const changesMade = (): boolean => {
     return username !== user?.username
      || email !== user?.email
      || firstName !== user?.firstName
      || lastName !== user?.lastName
  }

  useEffect(() => {
    if (userSearchTerm === '') {
      setFoundUsers([])
    }
    usersSearchQuery.refetch()
  }, [userSearchTerm])

  useEffect(() => {
    if (usersSearchQuery.data && userSearchTerm !== '') {
      
      const foundUsers: UserName[] = usersSearchQuery.data.usernames
        .filter((userName: UserName) => addingUserToOrganisation?.memberships?.every((membership: OrganisationMembership) => membership.user.userId !== userName.userId))
      
      setFoundUsers(foundUsers)
    }
  }, [usersSearchQuery.data])

  useEffect(() => {
    if (organisationsQuery.data) {
      const orgs: Organisation[] = [...organisationsQuery.data.organisations].sort((a: Organisation, b: Organisation) => a.name.localeCompare(b.name));
      setOrganisations(orgs)

      const userOrgs: Organisation[] = orgs.filter((org: Organisation) => {
        return org.memberships?.find((membership: OrganisationMembership) => {
          let userIsAdmin: boolean = false;
          if (membership.user.userId === user?.userId) {
            if (membership.role.level === 0) {
              userIsAdmin = true
            }
          }
          return userIsAdmin
        })
      })
      setUserOwnedOrganisations(userOrgs)
    }
  }, [organisationsQuery.data])

  const [createOrgMutation] = useMutation(api.organisations.mutations.CREATE_ORGANISATION)
  const createOrg = () => {
    createOrgMutation({variables: {name: newOrgName, contactEmail: newOrgContactEmail}})
      .then((result: any) => {
        helpers.log('Request successful, result: ', result)
        organisationsQuery.refetch()
      })
      .catch((error: any) => {
        helpers.log('Request not successful, error: ', error)
      })
  }

  const [addUserToOrgMutation] = useMutation(api.organisations.mutations.ADD_USER_TO_ORGANISATION)
  const addUserToOrg = (orgId: string, userId: string, roleName: string) => {
    addUserToOrgMutation({variables: {organisationId: orgId, userId: userId, roleName: roleName}})
      .then((result: any) => {
        helpers.log('Add user req successful, result: ', result)
        setSearchUserOpen(false)
        organisationsQuery.refetch()
      })
      .catch((error: any) => {
        helpers.log('add user req unsuccessful, error ', error)
      })
  }

  const [removeUserFromOrgMutation] = useMutation(api.organisations.mutations.REMOVE_USER_FROM_ORGANISATION)
  const removeUserFromOrg = (orgId: string, userId: string) => {
    removeUserFromOrgMutation({variables: {organisationId: orgId, userId: userId}})
      .then((result: any) => {
        helpers.log('Removed user from org successfully: ', result)
        organisationsQuery.refetch()
      })
      .catch((error: any) => {
        helpers.log('remove user req unsuccessful, error ', error)
      })
  }

  if (!user) {
    return null
  }

  return (
    <div className='view account'>
      <div className="flex justify-start gap-32 w-full mb-4 ">
        <div className='sidebar flex flex-col gap-1'>
          <Button 
            variant={active === 'profile' ? 'bwprimary' : 'bwsecondary'} 
            onClick={() => setActive('profile')}
            className={`justify-start gap-2 w-48 ${active === 'profile' && 'pointer-events-none'}`}>
              <Cookie className='h-4 w-4' />Profile
          </Button>
          <Button 
            variant={active === 'organisations' ? 'bwprimary' : 'bwsecondary'} 
            onClick={() => setActive('organisations')} 
            className={`justify-start gap-2 w-48 ${active === 'organisations' && 'pointer-events-none'}`}>
              <Building className='h-4 w-4' /> Organisations
          </Button>
        </div>
        <div className={`flex flex-col gap-2 w-92 ${active !== 'profile' && 'hidden'}`}>
          <h1 className='text-2xl text-bw-green mb-4'>Hello, {user.firstName}</h1>
          <h1 className='text-xl text-bw-green mb-4'>Your account settings.</h1>
          <div className='w-64 flex flex-col gap-3'>
            <div className='flex flex-col gap-0'>
              <span className='text-xs text-bw-green/20 pl-3'>Username</span>
              <Input className='' type='text' name='username' tabIndex={1} value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className='flex flex-col gap-0'>
              <span className='text-xs text-bw-green/20 pl-3'>Email address</span>
              <Input className={` ${emailError && 'border border-bw-ui-red'}`} type='text' name='email-address' tabIndex={1} value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='flex flex-col gap-0'>
              <span className='text-xs text-bw-green/20 pl-3'>First name</span>
              <Input className={``} type='text' name='first-name' tabIndex={2} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className='flex flex-col gap-0'>
              <span className='text-xs text-bw-green/20 pl-3'>Last name</span>
              <Input className={``} type='text' name='last-name' tabIndex={3} value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
            <div className='flex gap-1'>
              <Button variant='bwconfirm' onClick={() => updateAccount()} disabled={updatingAccount || !changesMade()}>
                {updatingAccount ? <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
        <div className={`flex flex-col gap-2 ${active !== 'organisations' && 'hidden'}`}>
          <h1 className='text-2xl text-bw-green mb-4'>Organisation Settings</h1>
          <h1 className='text-xl text-bw-green mb-4'>Create new organisation</h1>
          <div className='flex flex-col gap-3 w-64'>
            <div className='flex flex-col gap-0'>
              <span className='text-xs text-bw-green/20 pl-3'>Organisation name</span>
              <Input className={``} type='text' name='org-name' placeholder='Please enter a name' value={newOrgName} onChange={(e) => setNewOrgName(e.target.value)} />
            </div>
            <div className='flex flex-col gap-0'>
              <span className='text-xs text-bw-green/20 pl-3'>Contact email</span>
              <Input className={``}type='text' name='org-name' placeholder={user?.email} value={newOrgContactEmail} onChange={(e) => setNewOrgContactEmail(e.target.value)} />
            </div>
            <Button variant='bwconfirm' disabled={newOrgName === '' || !helpers.isEmailValid(newOrgContactEmail)} onClick={createOrg}>
              Create
            </Button>
          </div>
          <h1 className='text-xl text-bw-green mt-12 mb-4'>You are part of {organisations.length} organisations</h1>
          <div className='flex flex-col gap-4'>
            {organisations
              .sort()
              .map((org: Organisation, index: number) => {
              return (
                <div className='flex gap-1 text-bw-green p-4 border border-bw-green/10 rounded-xl' key={index}>
                  <div className='flex flex-col text-sm gap-1'>
                    {/* <div className='bg-bw-green/10 w-8 h-8 rounded-sm'></div> */} 
                    <div className='flex gap-1'>
                      <div className='font-bold'>{org.name}</div>
                      <div className='opacity-30'>({org.memberships?.length} members, {org.productCount} products)</div>
                    </div>
                    <div className='flex gap-1'>
                      Created by 
                      {org.createdBy ? 
                        <div>{org.createdBy.firstName} {org.createdBy.lastName}</div>
                        : <div className='italic opacity-70'>Deleted User</div>
                      }
                      
                    </div>
                    <Separator className='h-4'/>
                    
                    <div className='flex gap-1'>
                      <div className='flex gap-1'>
                        {org.memberships?.map((membership: OrganisationMembership) => 
                          <div className='flex items-center gap-1 rounded-md bg-bw-pale-sage/30 border border-bw-pale-sage p-2 pr-4 pl-4'>
                            {membership.user.userId === user?.userId ?
                              <div>
                                <div>You</div>
                                <div className='font-xs opacity-30 capitalize'>{membership.role.name}</div>
                              </div>
                              : 
                              <div>
                                <div>{membership.user.firstName} {membership.user.lastName}</div>
                                <div className='font-xs opacity-30 capitalize'>{membership.role.name}</div>
                              </div>
                            }
                            {userOwnedOrganisations.find(userOrg => userOrg.organisationId === org.organisationId) && membership.user.userId !== user?.userId ?
                              <Button variant='minimal' size='xs' className='pr-0' onClick={() => removeUserFromOrg(org.organisationId, membership.user.userId)}><XCircle className='h-4 w-4 text-ui-denial-red' /></Button>
                              : null
                            }
                          </div>
                        )}
                      </div>
                      {userOwnedOrganisations.find(userOrg => userOrg.organisationId === org.organisationId) ?
                        <Popover onOpenChange={(open: boolean) => handleSearchUserOpen(open, org)}>
                          <PopoverTrigger className='flex items-center gap-1 text-bw-green'>
                            <Button variant='bwconfirm' className='gap-1'>
                              <PlusCircle className='h-4 w-4' /> Add member
                            </Button>
                            {/* <span className='text-xs'>Add member</span> */}
                          </PopoverTrigger>
                          <PopoverContent>
                            <Input type='text' value={userSearchTerm} onChange={(e) => setUserSearchTerm(e.target.value)} placeholder='Search by username' />
                            <Spin className={`${usersSearchQuery.loading ? '' : 'hidden'} inline-spin absolute top-7 right-7 h-4 w-4`} color="#36463D" width="20px" height="20px" duration="0.3s" />
                            <div className={`flex flex-col gap-1 mt-1 ${foundUsers.length ? '' : 'hidden'}`}>
                              {foundUsers.map((userName: UserName) => 
                                <div
                                  className='flex justify-between items-center text-sm cursor-pointer rounded-md group hover:bg-ui-confirmation-green hover:text-white p-3'
                                  onClick={() => addUserToOrg(org.organisationId, userName.userId, 'member')}>
                                  {userName.username}
                                  <div className='opacity-0 text-xs group-hover:opacity-70'>Add</div>
                                </div>
                              )}
                            </div>
                          </PopoverContent>
                        </Popover> : null
                      }
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account;