import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import BwSymbol from '@/global-components/brand-assets/BwSymbol';
import { Eye, EyeOff, Send } from "lucide-react";

import { useAuth } from '../../context/auth';

import api from '../../api/bw-api';
import { useToast } from "@/global-components/components/ui/use-toast";
export type LoginProps = {
}

const SetNewPasswordWithToken = (props: LoginProps) =>  {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const { toast } = useToast()

  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [newPasswordRepeatVisible, setNewPasswordRepeatVisible] = useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [newPasswordRepeatError, setNewPasswordRepeatError] = useState<string | null>(null);

  const [setNewPasswordMutation] = useMutation(api.user.mutations.RESET_PASSWORD);

  useEffect(() => {
    
  }, [resetToken])

  const submitSetNewPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNewPasswordMutation({variables: {token: resetToken, newPassword1: newPassword, newPassword2: newPasswordRepeat }})
    .then(result => {
      if (result.data.passwordReset.success) {
        toast({
          title: "Password changed successfully",
          variant: "success",
          duration: 3000,
        })
        navigate("/login");
      } else {
        
        if (result.data.passwordReset.errors) {
          toast({
            title: "Something went wrong: " + result.data.passwordReset.errors?.nonFieldErrors[0].message,
            description: 'Please request a password reset link again from the login page.',
            variant: "destructive",
            duration: 8000,
          })
        } else {
          toast({
            title: "Something went wrong",
            description: "We're sorry. Either try again or contact us at software@batch.works",
            variant: "destructive",
            duration: 8000,
          })
        }
      }
    })
    .catch(err => {
      
    })
  }

  const isNewPaswordValid = (_password: string): boolean => {
    return (_password && _password.length >= 8 && /[A-Z]/.test(_password) && /\d/.test(_password)) ? true : false;
  }
  const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    if (isNewPaswordValid(event.target.value)) {
      setNewPasswordError(null)
    }
  }
  const handleNewPasswordBlur = () => {
    setNewPasswordError(isNewPaswordValid(newPassword) ? null : 'Password needs at least 8 characters, one upper case and one number.')
  }

  const isNewPasswordRepeatValid = (_newPasswordRepeat: string): boolean => {
    return newPassword === _newPasswordRepeat ? true : false;
  }
  const handleNewPasswordRepeatChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordRepeat(event.target.value);
    if (isNewPasswordRepeatValid(event.target.value)) {
      setNewPasswordRepeatError(null)
    }
  }
  const handleNewPasswordRepeatBlur = () => {
    setNewPasswordRepeatError(isNewPasswordRepeatValid(newPasswordRepeat) ? null : 'Passwords must match.')
  }
  
  return (
    <div className='view login flex flex-col items-center justify-center'>
      <BwSymbol className='w-16 h-16 mb-4 mt-8 fill-bw-green' />  
      <h1 className='text-center font-bold'>Batch.Works</h1>
      <span className='text-center mb-16 text-xs text-bw-green opacity-30 italic'>Beta</span>
      <div className='login-container w-[250px]'>
        <form onSubmit={submitSetNewPassword} className='mb-16 flex flex-col gap-1 w-full'>
          <div className='relative'>
            <Input type={newPasswordVisible ? 'text' : 'password'} tabIndex={2} name='password' placeholder="Your new password" value={newPassword} onChange={handleNewPasswordChange} onBlurCapture={handleNewPasswordBlur} className={newPasswordError ? 'border-ui-denial-red/50' : ''}/>
            <Button type='button' variant='minimal' size='xs' className='absolute m-auto top-0 bottom-0 right-1' onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
              {newPasswordVisible ? 
                <EyeOff className='h-4 w-4'/>
                : <Eye className='h-4 w-4'/>
              }
            </Button>
          </div>
          <div className={`password-hint text-xs text-ui-denial-red mb-2 pl-2 ${newPasswordError ? '' : 'hidden'}`}>8 characters, 1 upper case, 1 number</div>
          <div className='relative'>
            <Input type={newPasswordRepeatVisible ? 'text' : 'password'} tabIndex={2} name='password' placeholder="Repeat new password" value={newPasswordRepeat} onChange={handleNewPasswordRepeatChange} onBlurCapture={handleNewPasswordRepeatBlur} className={newPasswordRepeatError ? 'border-ui-denial-red/50' : ''} />
            <Button type='button' variant='minimal' size='xs' className='absolute m-auto top-0 bottom-0 right-1' onClick={() => setNewPasswordRepeatVisible(!newPasswordRepeatVisible)}>
              {newPasswordRepeatVisible ? 
                <EyeOff className='h-4 w-4'/>
                : <Eye className='h-4 w-4'/>
              }
            </Button>
          </div>
          <div className={`password-hint text-xs text-ui-denial-red mb-2 pl-2 ${newPasswordRepeatError ? '' : 'hidden'}`}>Passwords must match</div>   
          <Button variant="bwprimary" type="submit" disabled={(newPasswordError || newPasswordRepeatError || !newPassword) ? true : false} className='mt-4'>Reset Password</Button>
        </form>
      </div>
    </div>
  )
}

export default SetNewPasswordWithToken; 