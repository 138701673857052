import * as THREE from 'three';

export const customPhongShader = (
  materialColor: {r: number, g: number, b: number},
  lightColor1: {r: number, g: number, b: number},
  lightColor2: {r: number, g: number, b: number},
  lightPosition1: THREE.Vector3,
  lightPosition2: THREE.Vector3,
  shininess: number
) => {
  const rgbToVector3 = (rgb: {r: number, g: number, b: number}) => new THREE.Vector3(rgb.r / 400, rgb.g / 400, rgb.b / 400);

  const matColorVec = rgbToVector3(materialColor);
  const lightColorVec1 = rgbToVector3(lightColor1);
  const lightColorVec2 = rgbToVector3(lightColor2);

  const mat = new THREE.ShaderMaterial({
    uniforms: {
      Ka: { value: matColorVec },
      Kd: { value: matColorVec },
      Ks: { value: matColorVec },
      LightIntensity: { value: lightColorVec1 },
      LightPosition: { value: lightPosition1 },
      LightIntensity2: { value: lightColorVec2 },
      LightPosition2: { value: lightPosition2 },
      Shininess: { value: shininess }
    },
    vertexShader: `
      varying vec3 Normal;
      varying vec3 Position;

      void main() {
        Normal = normalize(normalMatrix * normal);
        Position = vec3(modelViewMatrix * vec4(position, 1.0));
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying vec3 Normal;
      varying vec3 Position;

      uniform vec3 Ka;
      uniform vec3 Kd;
      uniform vec3 Ks;
      uniform vec3 LightPosition;
      uniform vec3 LightIntensity;
      uniform vec3 LightPosition2;
      uniform vec3 LightIntensity2;
      uniform float Shininess;

      vec3 phongLight(vec3 lightPos, vec3 lightIntensity) {
        vec3 n = normalize(Normal);
        vec3 s = normalize(lightPos - Position);
        vec3 v = normalize(-Position);
        vec3 r = reflect(-s, n);

        vec3 ambient = Ka;
        vec3 diffuse = Kd * max(dot(s, n), 0.0);
        vec3 specular = Ks * pow(max(dot(r, v), 0.0), Shininess);

        return lightIntensity * (ambient + diffuse + specular);
      }

      void main() {
        vec3 light1 = phongLight(LightPosition, LightIntensity);
        vec3 light2 = phongLight(LightPosition2, LightIntensity2);
        gl_FragColor = vec4(light1 + light2, 1.0);
      }
    `
  });

  return mat;
}
