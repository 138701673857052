import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import './button.scss'

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-ui-denial-red text-destructive-foreground hover:bg-destructive/90",
        destructiveoutline:
          "bg-ui-denial-red/2 border border-solid border-ui-denial-red-brighter text-ui-denial-red hover:bg-ui-denial-red-super-bright",
        destructiveminimal:
          "text-ui-denial-red opacity-80 hover:opacity-100",
        outline:
          "border border-bw-grey bg-none hover:bg-bw-pale-sage hover:text-accent-foreground",
        outlineinverted:
          "invert border border-bw-palesage text-bw-palesage hover:bg-accent hover:text-accent-foreground",
        outlinehighlight:
          "border border-bw-highlight bg-bw-highlight/10 text-bw-green hover:bg-bw-highlight/30 hover:text-black",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-bw-pale-sage hover:text-bw-green",
        destructiveghost: "text-ui-denial-red hover:bg-bw-grey/50",
        ghostinverted: "invert hover:bg-accent hover:text-accent-foreground",
        minimal: "opacity-60 hover:opacity-100 hover:underline",
        inline: "h-auto w-auto text-ui-denial-red opacity-50 p-0 m-0 hover:opacity-100",
        link: "text-primary underline-offset-4 hover:underline",
        bwprimary: 
          "border border-solid border-ui-pale-sage-button-border text-bw-green bg-bw-pale-sage hover:bg-bw-green hover:border-bw-green hover:text-bw-pale-sage",
        bwsecondary: 
          "border border-solid border-ui-pale-sage-button-border text-bw-green hover:bg-bw-pale-sage",
        bwonbwgreen:
          "border border-solid border-ui-button-on-green-stroke bg-ui-button-on-green-fill text-xs hover:bg-bw-pale-sage hover:text-bw-green",
        bwonbwdarkgreen:
          "border border-solid border-bw-dark-tinted-green-brighter bg-bw-dark-tinted-green text-xs hover:bg-bw-pale-sage hover:text-bw-green",
        bwconfirm:
          "border-none bg-ui-confirmation-green text-white hover:bg-ui-confirmation-green/90"
      },
      size: {
        default: "h-10 px-4 py-2",
        minimal: "h-10 px-2 py-2",
        sm: "h-8 px-3",
        smnoborder: "h-8",
        xs: "h-6 px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        smallicon: "h-8 w-8",
        zeromargin: "h-4 w-4 p-0 m-0"
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
