
type progressBarProps = {
  height: number;
  progress: number;
  bright?: boolean;
  className?: string;
}

const ProgressBar = (props: progressBarProps) => {
  const style = {
    height: props.height + 'px',
  }
  return (
    <div className={props.bright ? 'progress-container bg-bw-green rounded-full bg-opacity-30 ' + props.className : 'progress-container bg-bw-pale-sage rounded-full bg-opacity-30 ' + props.className} style={style}>
      <div className={props.bright ? 'progress h-full rounded-full bg-bw-green transition-all' : 'progress h-full rounded-full bg-bw-pale-sage transition-all'} style={{width: props.progress + '%'}}></div>
    </div>
  )
}

export default ProgressBar;