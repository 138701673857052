// React imports
import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from '@apollo/client';

// Context and API imports
import api from '../../api/bw-api';

// Type imports
import { User, ProductType, Organisation, ProductGroup } from '@/global-components/types'

// Component imports
import { useAuth } from '../../context/auth';
import { useToast } from "@/global-components/components/ui/use-toast";
import ProductPreview from './ProductPreview';
import Combobox from '@/global-components/components/combobox/Combobox';
import { filter, groupBy, uniq } from 'lodash';
import GroupPreview from './GroupPreview';
import InfoTooltip from '@/global-components/components/bw/InfoTooltip';


type ProductStatus = 'unapproved' | 'approved' | 'production';

/* LOVELY OLD HYPERSUPER WORK IN HERE */
interface OrganisationsProductsProps {
  groupByProjects?: boolean;
}

const OrganisationsProducts = (props: OrganisationsProductsProps) => {
  const { user } = useAuth()
  const { toast } = useToast()

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reload = queryParams.get('reload');

  const [products, setProducts] = useState<ProductType[]>([]);
  const [groupsOfSharedProducts, setGroupsOfSharedProducts] = useState<ProductGroup[]>([])
  const [groups, setGroups] = useState<ProductGroup[]>([]);
  const [productsOfGroups, setProductsOfGroups] = useState<ProductType[]>([])

  const [gridColumns, setGridColumns] = useState<string>('grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8');
  useEffect(() => {
    setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    const handleResize = () => {
      setGridColumns('grid-cols-' + Math.round(window.innerWidth / 450));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getOrganisationsProducts = useQuery(api.products.queries.GET_USERS_ORGANISATIONS_PRODUCTS, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: user?.userId,
      includeMessages: true,
      includeFiles: true
    },
    skip: !user
  })

  const getOrganisationsGroupsWithProducts = useQuery(api.products.queries.GET_GROUPS_OF_ORGANISATIONS_WITH_PRODUCTS, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
  })

  const [organisations, setOrganisations] = useState<Organisation[]>([])
  const [filteredOrganisationId, setFilteredOrganisationId] = useState<string>('')
  const getOrganisations = useQuery(api.organisations.queries.GET_ORGANISATIONS, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
  })

  const sortProductsByStatus = (a: any, b: any): number => {
    const order: { [key: string]: number } = {
      'production': 1,
      'approved': 2,
      'unapproved': 3,
    };
  
    return order[a.status.toLowerCase()] - order[b.status.toLowerCase()];
  };

  const productPartOfFilteredOrg = (product:ProductType): boolean => {
    if (filteredOrganisationId === '') {
      return true
    } else {
      return product.organisations?.find(org => org.organisationId === filteredOrganisationId) ? true: false
    }
  }

  useEffect(() => {
    if (getOrganisationsProducts.data?.productsForOrganisations)  {
      const sortedProducts: ProductType[] = [...getOrganisationsProducts.data?.productsForOrganisations].sort(sortProductsByStatus);
      setProducts(sortedProducts);
      const uniqueGroupsOfSharedProducts: ProductGroup[] = []
      for (const product of sortedProducts) {
        if (!uniqueGroupsOfSharedProducts.find(group => group.groupId === product.groupId.groupId)) {
          uniqueGroupsOfSharedProducts.push(product.groupId)
        }
      }
      setGroupsOfSharedProducts(uniqueGroupsOfSharedProducts)
      // setGroupsOfSharedProducts(
      //   sortedProducts.map(product => product.groupId)
      //   .filter((group: ProductGroup, index: number, self: ProductGroup[]) =>
      //     index === self.findIndex(g => g.groupId === group.groupId)
      //   )
      // )
    }
  }, [getOrganisationsProducts.data])

  useEffect(() => {
    if (getOrganisationsGroupsWithProducts.data) {
      setGroups(getOrganisationsGroupsWithProducts.data.groupsOfOrganisationsWithProducts.groups)
      setProductsOfGroups(getOrganisationsGroupsWithProducts.data.groupsOfOrganisationsWithProducts.products)
    }
  }, [getOrganisationsGroupsWithProducts.data])

  useEffect(() => {
    if (getOrganisations.data?.organisations) {
      setOrganisations(getOrganisations.data?.organisations)
    }
  }, [getOrganisations.data])

  if (products.length === 0) {
    return null
  }


  return (
    <div className='mt-24'>
      <div className='flex justify-between mb-4'>
        <div className='text-bw-green flex items-center gap-2 z-50'>
          <h1 className='text-2xl'>Shared with you via {filteredOrganisationId === '' ? 'your organisations' : organisations.find(org => org.organisationId === filteredOrganisationId)?.name}</h1>
          {props.groupByProjects && <InfoTooltip>To see all shared products, please switch off <b>View Projects</b></InfoTooltip>}
        </div>
        <Combobox
            title='Filter by organisation'
            items={organisations.map(org => { return {
              value: org.organisationId,
              name: org.name
            }})}
            value={filteredOrganisationId}  
            capitalise
            wide
            selectCallback={(newValue: any) => setFilteredOrganisationId(newValue)} />
      </div>
      {props.groupByProjects ? 
        <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
          {groups.map((group: ProductGroup, index: number) => (
            <GroupPreview
              group={group}
              key={index}
              products={productsOfGroups.filter(product => product.groupId.groupId === group.groupId)}
            />
          ))}
          {/* {groupsOfSharedProducts.map((group: ProductGroup, index: number) => (
              <GroupPreview
                group={group}
                products={products.filter(product => product.groupId.groupId === group.groupId)}
                noAccess
              />
            ))
          } */}
        </div>
        :
        <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
          {products.filter(productPartOfFilteredOrg).map((product: any, index: number) => (
            <ProductPreview 
              key={index} 
              product={product} 
              navigate={navigate} 
              index={index}
              showOrg 
            />
          ))}
        </div>
      }
      {products.filter(productPartOfFilteredOrg).length ? null : 'Sorry, but no products are part of the filtered organisation'}
    </div>
  )
}

export default OrganisationsProducts