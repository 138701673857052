import { ProductGroup, ProductType } from '@/global-components/types';
import { Folder } from 'lucide-react';
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, MouseEvent, useRef } from 'react';

type GroupPreviewProps = {
  group: ProductGroup;
  products: ProductType[];
  noAccess?: boolean;
}

const GroupPreview = ({group, products}: GroupPreviewProps) => {
  const navigate = useNavigate();
  const goToGroup = () => {
    navigate('/project/' + group.groupId);
  }

  return (
    <div className={`group-preview ${products.length === 0 ? 'opacity-30' : ''}`} onClick={() => goToGroup()}>
      <div className='name text-sm p-2 flex gap-1 font-bold items-center'>
        <Folder className='h-4 w-4' />
        {group.groupTitle} 
        <span className='opacity-30 font-normal'>({products.length} products)</span>
      </div>
      <div className='products p-2 grid grid-cols-3 gap-1'>
        {products.map((product: ProductType, index: number) => (
          <div 
            className='mini-product-preview aspect-square rounded-sm border bg-bw-grey/10 border-bw-green/10 text-xs flex flex-col justify-end p-2'
            key={index}>
              {product.productTitle}
          </div>
        ))}
      </div>
    </div>
  )
}

export default GroupPreview