import React, { useState, useEffect } from 'react';
import { SlicerParameters } from '@/global-components/types';
import { Input } from '@/global-components/components/ui/input';
import _ from 'lodash';
import { Diameter, Fan, Layers2, Minus, MoveHorizontal, Rotate3D, Square, Thermometer, TrainFront } from 'lucide-react';
import { Button } from '@/global-components/components/ui/button';

interface SlicerSettingsProps {
  show: boolean;
  settings: SlicerParameters;
  isSlicing: boolean;
  resliceWith: Function;
}

const SlicerSettings = ({ show, settings, resliceWith, isSlicing }: SlicerSettingsProps) => {
  const [open, setOpen] = useState<boolean>(show);
  const [parameters, setParameters] = useState<SlicerParameters>(settings);

  const [slicingRequested, setSlicingRequested] = useState<boolean>(false)

  const [parametersChanged, setParametersChanged] = useState<boolean>(false)
  const [updatedParameters, setUpdatedParameters] = useState<SlicerParameters | undefined>(undefined)

  const [rotateX, setRotateX] = useState<number>(settings.rotateX)
  const [rotateY, setRotateY] = useState<number>(settings.rotateY)
  const [rotateZ, setRotateZ] = useState<number>(settings.rotateZ)
  const [nozzleDiameter, setNozzleDiameter] = useState<number>(settings.nozzleDiameter);
  const [layerHeight, setLayerHeight] = useState<number>(settings.layerHeight);
  const [extrusionWidth, setExtrusionWidth] = useState<number>(settings.extrusionWidth);
  const [bottomSolidLayers, setBottomSolidLayers] = useState<number>(settings.bottomSolidLayers);
  const [firstLayerHeight, setFirstLayerHeight] = useState<number>(settings.firstLayerHeight);
  const [speed, setSpeed] = useState<number>(settings.speed);
  const [fanSpeed, setFanSpeed] = useState<number>(settings.fanSpeed);
  const [temperature, setTemperature] = useState<number>(settings.temperature);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    setInitialValues()
  }, [settings]);

  // useEffect(() => {
  //   updateParameters()
  // }, [rotateX, rotateY, rotateZ])

  useEffect(() => {
    if (!isSlicing) {
      setSlicingRequested(false)
    }
  }, [isSlicing])

  const setInitialValues = () => {
    setParameters(settings)
    setUpdatedParameters(undefined)
    setRotateX(settings.rotateX)
    setRotateY(settings.rotateY)  
    setRotateZ(settings.rotateZ)
    setNozzleDiameter(settings.nozzleDiameter)
    setLayerHeight(settings.layerHeight)
    setExtrusionWidth(settings.extrusionWidth)
    setFirstLayerHeight(settings.firstLayerHeight)
    setSpeed(settings.speed)
    setFanSpeed(settings.fanSpeed)
    setTemperature(settings.temperature)
  }

  const updateParameters = () => {
    const _updatedParameters: SlicerParameters = {
      ...parameters,
      rotateX: rotateX,
      rotateY: rotateY,
      rotateZ: rotateZ,
      nozzleDiameter: nozzleDiameter,
      layerHeight: layerHeight,
      extrusionWidth: extrusionWidth,
      bottomSolidLayers: bottomSolidLayers,
      firstLayerHeight: firstLayerHeight,
      speed: speed,
      fanSpeed: fanSpeed,
      temperature: temperature
    };

    if (updatedParameters === undefined || !_.isEqual(_updatedParameters, parameters)) {
      setParametersChanged(true)
      setUpdatedParameters(_updatedParameters)
    }
  };

  const triggerReslice = () => {
    setSlicingRequested(true)
    setParametersChanged(false)
    resliceWith(updatedParameters);
  }

  const resetValues = () => {
    setParametersChanged(false)
    setInitialValues()
  }

  return (
    <div className={`grid grid-flow-col transition-all duration-300 ease-out-expo ${open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
      <div className={`overflow-hidden flex flex-col gap-8 px-14 ${open ? '' : 'blur-sm opacity-0 -translate-y-2'}`}>
        <div className={`flex gap-2 justify-between transition-all origin-top-left duration-300 ease-out-expo`}>
          <div className={`slicer-settings relative flex flex-col gap-2 rounded-md`}>
            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Diameter className='h-4 w-4'/> Nozzle Diameter:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={0.1}
                min={0.1}
                name='nozzle-diameter'
                value={nozzleDiameter}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setNozzleDiameter(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Layers2 className='h-4 w-4'/> Layer Height:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={0.05}
                min={0.05}
                name='layer-height'
                value={layerHeight}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setLayerHeight(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><MoveHorizontal className='h-4 w-4'/>Extrusion Width:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={0.05}
                min={0.05}
                name='extrusion-width'
                value={extrusionWidth}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setExtrusionWidth(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Square className='h-4 w-4'/>Bottom Solid Layers:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={1}
                min={0}
                name='bottom-solid-layers'
                value={bottomSolidLayers}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setBottomSolidLayers(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Minus className='h-4 w-4'/>First Layer Height:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={0.05}
                min={0.05}
                name='first-layer-height'
                value={firstLayerHeight}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setFirstLayerHeight(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            {/* <div className='flex gap-2 items-center'>
              <Button variant='bwsecondary' disabled={isSlicing || slicingRequested} onClick={() => setRotateX(rotateX + 45)}>Rotate X 45°</Button>
              <div className='text-xs opacity-30'>Current: {settings.rotateX}°</div>
            </div>
            <div className='flex gap-2 items-center'>
              <Button variant='bwsecondary' disabled={isSlicing || slicingRequested} onClick={() => setRotateX(rotateY + 45)}>Rotate Y 45°</Button>
              <div className='text-xs opacity-30'>Current: {rotateY}°</div>
            </div>
            <div className='flex gap-2 items-center'>
              <Button variant='bwsecondary' disabled={isSlicing || slicingRequested} onClick={() => setRotateX(rotateZ + 45)}>Rotate Z 45°</Button>
              <div className='text-xs opacity-30'>Current: {rotateZ}°</div>
            </div> */}
          </div>
          <div className='slicer-settings relative flex flex-col gap-2 rounded-md'>
            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><TrainFront className='h-4 w-4'/> Extruder Speed:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={5}
                min={5}
                max={400}
                name='speed'
                value={speed}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setSpeed(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Fan className='h-4 w-4'/>Fan Speed:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={5}
                min={0}
                max={255}
                name='fan-speed'
                value={fanSpeed}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setFanSpeed(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Thermometer className='h-4 w-4'/> Print Temperature:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={1}
                min={1}
                max={250}
                name='temperature'
                value={temperature}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setTemperature(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Rotate3D className='h-4 w-4'/> Rotate X:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={45}
                min={0}
                max={360}
                name='rotationx'
                value={rotateX}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setRotateX(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>           

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Rotate3D className='h-4 w-4'/> Rotate Y:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={45}
                min={0}
                max={360}
                name='rotationy'
                value={rotateY}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setRotateY(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>           

            <div className='flex items-center justify-between gap-2'>
              <span className='flex items-center gap-1 text-xs text-bw-white pl-3'><Rotate3D className='h-4 w-4'/> Rotate Z:</span>
              <Input
                className={`shrink w-min`}
                type='number'
                step={45}
                min={0}
                max={360}
                name='rotationz'
                value={rotateZ}
                disabled={isSlicing || slicingRequested}
                onChange={(e) => setRotateZ(Number(e.target.value))}
                onBlur={() => updateParameters()}
              />
            </div>            
          </div>
        </div>
        <div className='flex gap-1 items-center w-full justify-end pt-2 border border-0 border-t border-bw-grey'>
          {/* <Button variant='bwsecondary' disabled={isSlicing || slicingRequested} onClick={() => setRotateX(rotateX + 45)}>Rotation X +45°</Button>
          <Button variant='bwsecondary' disabled={isSlicing || slicingRequested} onClick={() => setRotateX(rotateY + 45)}>Rotate Y +45°</Button>
          <Button variant='bwsecondary' disabled={isSlicing || slicingRequested} onClick={() => setRotateX(rotateZ + 45)}>Rotate Z +45°</Button> */}
          <Button variant='bwsecondary' disabled={!parametersChanged || isSlicing || slicingRequested} onClick={() => resetValues()}>Reset</Button>
          <Button variant='bwconfirm' disabled={!parametersChanged || isSlicing || slicingRequested} onClick={() => triggerReslice()}>Reslice</Button>
        </div>
      </div>
    </div>
  );
};

export default SlicerSettings;
