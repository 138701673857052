import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/auth';
import { User, UserPreferences } from '@/global-components/types'
import helpers from "@/global-components/components/helpers"
import { useQuery, useMutation } from '@apollo/client';
import './Home.scss';
import UserProducts from '@/components/userProducts/UserProducts'
import OrganisationsProducts from '@/components/userProducts/OrganisationsProducts';
import ProductList from '@/components/ProductsList/ProductsList'
import OpenSubmissions from '@/components/openSubmissions/OpenSubmissions';
import api from '../../api/bw-api';
import { Switch } from "@/global-components/components/ui/switch"
import { Tabs,  TabsList, TabsTrigger } from "@/global-components/components/ui/tabs"
import { Grip, LayoutGrid, List } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/global-components/components/ui/tooltip"


const Home = () =>  {
  const { user } = useAuth();
  const [groupByProjects, setGroupByProjects] = useState<boolean>(false)
  const [updatingUserPreferences, setUpdatingUserPreferences] = useState<boolean>(false)

  const [updateUserPreferences] = useMutation(api.user.mutations.UPDATE_USER_PREFERENCES)

  const updateGroupByProjects = (value: boolean) => {
    setGroupByProjects(value);
    setUpdatingUserPreferences(true)
    updateUserPreferences({
      variables: {
        sortHomeByProjects: value
      }
    }).then((result: any) => {
    }).catch((error: any) => {
      helpers.log(error)
    }).finally(() => {
      setUpdatingUserPreferences(false)
    })
  }

  useEffect(() => {
    if (updatingUserPreferences) return
    setGroupByProjects(user?.userPreferences?.sortHomeByProjects ? user.userPreferences.sortHomeByProjects : false)
  }, [user])

  if (!user) {
    return null;
  } else if (user?.isSuperuser) {
    console.log(user)
    return (
      <div className='view home'>
        { user.userPreferences?.reviewerMode || !user.userPreferences ? <ProductList /> : null }
        <div className=''>
          <div className="flex justify-between items-center w-full mb-4">
            <h1 className='text-2xl text-bw-green flex gap-1 items-center'>
              Your Products, {user.firstName}
            </h1>
            <div className='view-settings flex justify-end items-center gap-8'>
              <div className='flex items-center gap-1'>
                <Switch checked={groupByProjects} onCheckedChange={updateGroupByProjects}/>
                <span className={groupByProjects ? 'text-xs text-bw-green font-medium' : 'text-xs text-bw-green font-medium opacity-30'} >View projects</span>
              </div>
              <Tabs defaultValue="grid" className="w-max disabled">
                <TabsList className='bg-bw-grey'>
                  <TabsTrigger value="grid"><Grip className='w-3 h-3' /></TabsTrigger>
                  <TabsTrigger value="list"><List className='w-3 h-3' /></TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
          <UserProducts groupByProjects={groupByProjects}/>
        </div>
        <OrganisationsProducts />
      </div>
    );
  } else {
    return (
      <div className='view home'>
        <div className="flex justify-between items-center w-full mb-4">
          <h1 className='text-2xl text-bw-green'>Your {groupByProjects ? 'Projects' : 'Products'}, {user.firstName}</h1>
          <div className='view-settings flex justify-end items-center gap-8'>
            <div className='flex items-center gap-1'>
              <Switch checked={groupByProjects} onCheckedChange={updateGroupByProjects}/>
              <span className={groupByProjects ? 'text-xs text-bw-green font-medium' : 'text-xs text-bw-green font-medium'} >View projects</span>
            </div>
            <Tabs defaultValue="grid" className="w-max">
              <TabsList className='bg-bw-grey'>
                <TabsTrigger value="grid"><Grip className='w-3 h-3' /></TabsTrigger>
                <TabsTrigger value="list"><List className='w-3 h-3' /></TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>

        {/* <OpenSubmissions groupByProjects={groupByProjects}/> */}
        <UserProducts groupByProjects={groupByProjects} />
        <OrganisationsProducts groupByProjects={groupByProjects}/>
      </div>
    );
  }
}

export default Home;