import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { useMutation, useQuery } from '@apollo/client';
import { Spin } from "react-cssfx-loading";
import { useAuth } from '../../context/auth';
import { Version, User, UserPreferences } from '@/global-components/types'
import Combobox from '@/global-components/components/combobox/Combobox'
import { Button } from '@/global-components/components/ui/button'
import { Switch } from '@/global-components/components/ui/switch'
import api from '../../api/bw-api'
import ProductDelete from '../../views/product/productDelete/ProductDelete';

import helpers from "@/global-components/components/helpers"
import { Badge } from "@/global-components/components/ui/badge";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/global-components/components/ui/table"

import './ProductsList.scss';
import { get } from 'http';
import { getUser } from '@/services/user';

interface Submission {
  productId: string;
  productTitle: string;
  clientName: string;
  createdBy: User;
  productVersion: Version[];
  submissionDate: string;
  messagemodelSet: any[];
  inDepth?: boolean;
  latestVersion: boolean;
  noOfUploadedFiles: number;
  status: string;
}

type ProductStatus = 'unapproved' | 'approved' | 'production';

const ProductsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlQuery = new URLSearchParams(location.search);
  const userFilterUrl = urlQuery.get('user');
  const statusFilterUrl = urlQuery.get('status');
  const user: User | null = useAuth().user;

  const ProductStatusFilterItems: {value: ProductStatus}[] = [{value: 'unapproved'}, {value: 'approved'}, {value: 'production'}]

  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const getAllProducts = useQuery(api.products.queries.GET_PRODUCTS,
    {
      variables: {
        includeFiles: false,
        includeMessages: true
      },
      pollInterval: 5000,
    }
  );

  const [statusFilter, setStatusFilter] = useState<string>(localStorage.getItem('statusFilter') || '');
  const [userFilter, setUserFilter] = useState<string>(localStorage.getItem('userFilter') || '');
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const getAllUsersQuery = useQuery(api.user.queries.ALL_USERS)

  const goToProduct = (productRef: string) => {
    navigate('/product/' + productRef);
  }

  const getNumberUnreadMessages = (product: any): number => {
    return product.messagemodelSet?.filter((message: any) => !message.read)?.length
  }

  const clearFilters = () => {
    setUserFilter('');
    setStatusFilter('');
  }
  const filterByUserId = (submission: Submission) => {
    if (userFilter === '') {
      return true
    } else {
      return submission.createdBy.userId === userFilter;
    }
  }

  const filterByStatus = (submission: Submission) => {
    if (statusFilter === '') {
      return true
    } else {
      return submission.status.toLowerCase() === statusFilter;
    }
  }

  useEffect(() => {
    localStorage.setItem('statusFilter', statusFilter);
    localStorage.setItem('userFilter', userFilter);
  }, [statusFilter, userFilter]);

  useEffect(() => {
    setAllUsers(getAllUsersQuery.data?.allUsers)
  }, [getAllUsersQuery.data])

  useEffect(() => {
    if (!getAllProducts.data) return;
    const newSubmissions: Submission[] = getAllProducts.data.products.map((product: any) => ({
      productId: product.productId,
      productTitle: product.productTitle,
      clientName: '', 
      createdBy: product.createdBy,
      productVersion: [{
        reference: product.productVersion[0]?.reference
      }],
      submissionDate: product.createdAt,
      messagemodelSet: product.messagemodelSet,
      noOfUploadedFiles: product.noOfUploadedFiles,
      latestVersion: product.latestVersion,
      status: product.status
    }));



    newSubmissions.sort((a, b) => (b.submissionDate > a.submissionDate) ? 1 : -1);
    
    setSubmissions(newSubmissions);
  }, [getAllProducts.data]); 

  if (getAllProducts.loading) return <Spin className="center-spin" color="#36463D" width="20px" height="20px" duration="0.1s" />;

  return (
    <div className='open-submissions mb-16'>
      <div className='flex justify-between mb-8 items-center'>
        <div className='flex gap-2 items-center'>
          <h1 className='text-2xl text-bw-green'>
            {userFilter ? allUsers?.find((user: User) => user.userId === userFilter)?.firstName + "'s Products" : 'All Products'}
          </h1>
        </div>
        <div className='filter flex gap-2 items-center'>
          <div className='text-sm'>
            {userFilter || statusFilter 
              ? <Button variant='link' onClick={clearFilters}>Clear Filter</Button>
              : <div className='opacity-30'>Filter</div>
            }
          </div>
          <Combobox
            title='Status'
            items={ProductStatusFilterItems}
            value={statusFilter}
            capitalise
            selectCallback={(newValue: any) => setStatusFilter(newValue)} />
          <div className='filter-by-user'>
            {allUsers?.length ? 
              <Combobox 
                title='User'
                wide
                items={allUsers?.map(user => ({name: user.firstName + ' ' + user.lastName, value: user.userId}))}
                value={userFilter}
                selectCallback={(newValue: any) => setUserFilter(newValue)} />
              : null
            }
          </div>
        </div>
      </div>
      <Table>
        <TableHeader className='text-xs text-bw-green'>
          <TableRow className='border-bw-pale-sage border-b'>
            <TableHead className="text-bw-green/30">Product Name</TableHead>
            <TableHead className="text-bw-green/30">Client</TableHead>
            <TableHead className="text-bw-green/30">User</TableHead>
            <TableHead className="text-right text-bw-green/30">Submitted On</TableHead>
            <TableHead className="text-right text-bw-green/30">Initial Files</TableHead>
            <TableHead className="text-bw-green/30">Status</TableHead>
            <TableHead className="text-bw-green/30"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {submissions.filter(filterByUserId).filter(filterByStatus).map((submission: Submission) => (
            <TableRow key={submission.productId} 
              className='submission border-b border-bw-pale-sage rounded-md h-4 hover:bg-ui-table-row-hover'>
              <TableCell onClick={() => goToProduct(submission.productVersion[0].reference)} className="font-medium relative">
                <div className='flex items-center'>
                  {submission.productTitle} {getNumberUnreadMessages(submission)}
                  {getNumberUnreadMessages(submission) > 0 ? <div className='notification-badge inline right-auto text-xxs font-bold bg-ui-notification-red text-white'>{getNumberUnreadMessages(submission)}</div> : null}
                </div>
              </TableCell>
              <TableCell onClick={() => goToProduct(submission.productVersion[0].reference)} >{submission.clientName}</TableCell>
              <TableCell onClick={() => goToProduct(submission.productVersion[0].reference)} >
                {submission.createdBy.userId === user?.userId ? 
                  'You'
                  : submission.createdBy.firstName + ' ' + submission.createdBy.lastName
                }
              </TableCell>
              <TableCell onClick={() => goToProduct(submission.productVersion[0].reference)}  className="text-right">{helpers.dateAndTime(submission.submissionDate)}</TableCell>
              <TableCell onClick={() => goToProduct(submission.productVersion[0].reference)}  className="text-right">{submission.noOfUploadedFiles}</TableCell>
              <TableCell onClick={() => goToProduct(submission.productVersion[0].reference)} ><Badge variant={submission?.status.toLowerCase() as ProductStatus}>{submission?.status}</Badge></TableCell>
              <TableCell className='text-right'>
                <ProductDelete productId={submission.productVersion[0].reference} buttonVariant='destructiveghost' productDeleted={getAllProducts.refetch()}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default ProductsList;