import React, { useState, useRef, useEffect } from 'react';
import { CirclePicker, ColorResult } from 'react-color';
import { Canvas, ThreeEvent, useFrame, useThree, useLoader, extend } from '@react-three/fiber';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import verb, { geom } from 'verb-nurbs-web'
import { Circle, AccumulativeShadows, Line, Extrude, Environment, OrbitControls, Grid, Plane, CameraControls, SoftShadows, BakeShadows, RandomizedLight, ContactShadows, Sphere, Detailed } from '@react-three/drei';
import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { Model, Layer, ExtruderPoint, FileType } from '@/global-components/types'
import { parseGcode } from '../GCodeParser';
import { Switch } from "@/global-components/components/ui/switch"
import { Input } from '@/global-components/components/ui/input'
import { CustomLinearPath } from './CustomLinearPath';
import SetOrbit from './SetOrbit'
import Floor from './Floor'
import { customPhongShader } from './Shaders';
import { createChamferedRectShape, createRoundedRectShape, vectorsFromPoints, modelToThreeLines, calculateExtrusionWidth, 
  getLayerHeight, getAverageExtrusionWidthForVaseLayer, calculateOffsetPoint } from './helpers';
import ProgressBar from '../progressBar/ProgressBar';
import { Button } from '../../ui/button';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import { Spin } from 'react-cssfx-loading';

THREE.Mesh.prototype.raycast = acceleratedRaycast;
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

type GCodeViewerProps = {
  gcodeFile: FileType | undefined;
  nozzleDiameter: number | undefined;
}

const floorSize: number = 300
const segmentResolutionThreshold: number = 130000;

const GCodeViewer: React.FC<GCodeViewerProps> = ({gcodeFile, nozzleDiameter}) => {
  const [lines, setLines] = useState<THREE.Vector3[][]>([])
  const [hoveredLine, setHoveredLine] = useState<number>(-1)
  const [activeLayer, setActiveLayer] = useState<number>(-1)
  const [highlightedGCode, setHighlightedGCode] = useState<string>('')
  const [showModel, setShowModel] = useState<boolean>(false)
  const [showGrid, setShowGrid] = useState<boolean>(true)
  const [filamentDiameter, setFilamentDiameter] = useState<number>(1.75)
  const [shadowsEnabled, setShadowsEnabled] = useState<boolean>(true)
  const lineModel = useRef<any>(null)

  const cameraControls = useRef<CameraControls>(null)
  const [cameraBeenCentered, setCameraBeenCentered] = useState<boolean>(false)
  
  const [mesh, setMesh] = useState<any>(null)

  const meshRef: any = useRef<any>(null)  

  const [model, setModel] = useState<Model | null>(null);
  const [geometry, setGeometry] = useState<THREE.BufferGeometry | undefined>(undefined);
  const [progressGeometry, setProgressGeometry] = useState<number>(0)
  const [shaderMaterial, setShaderMaterial] = useState<THREE.ShaderMaterial>(
    customPhongShader({r: 200, g: 200, b: 200}, 
      {r: 200, g: 200, b: 200}, 
      {r: 200, g: 200, b: 200},
      new THREE.Vector3(500, 2000, 200),
      new THREE.Vector3(500, 2000, 200),
      20)
  )


  const [gcodeFilamentMatt, setGcodeFilamentMatt] = useState<boolean>(false)
  const [gcodeFilamentColor, setGcodeFilamentColor] = useState<string>('#FFFEF3')

  const [phongShader, setPhongShader] = useState<THREE.MeshPhongMaterial>(
    new THREE.MeshPhongMaterial({color: gcodeFilamentColor, shininess: gcodeFilamentMatt ? 1 : 30, specular: gcodeFilamentMatt ? '#EEEEEE' :'#FFFFFF'})
  )

  const filamentColourChanged = (color: ColorResult) => {
    setGcodeFilamentColor(color.hex)
  }

  useEffect(() => {
    setPhongShader(new THREE.MeshPhongMaterial({color: gcodeFilamentColor, shininess: gcodeFilamentMatt ? 1 : 30, specular: gcodeFilamentMatt ? '#EEEEEE' :'#FFFFFF'}))
  }, [gcodeFilamentColor, gcodeFilamentMatt])

  useEffect(() => {
    console.log('GCODE File is being (re)placed because file updated')
    if (!gcodeFile) return
    fetch(gcodeFile.presignedUrl)
      .then(response => response.text())
      .then((response) => {
        const text: string = response;
        if (typeof text === 'string') {
          loadGcode(text);
        }
      })
  }, [gcodeFile])

  const gcodeShortcuts = (e: KeyboardEvent) => {
    const activeElement = document.activeElement;

    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      activeElement instanceof HTMLSelectElement
    ) {
      return
    }
    
    if (e.key == 'p' || e.key == 'P') {
      setShowModel(showModel => !showModel)
    } else if (e.key == 'g' || e.key == 'G') {
      setShowGrid(showGrid => !showGrid)
    } else if (e.key == 'c' || e.key == 'C') {
      centerCamera()
    }
  }

  const centerCamera = () => {
    if (model && cameraControls.current) {
      cameraControls.current.setLookAt(model.center.x-150, model.center.y, model.center.z + 150, model.center.x, model.center.y, model.center.z)
    }
  }

  const loadGcode = (gcode: string) => {
    const _model: Model | null = parseGcode(gcode, nozzleDiameter, filamentDiameter);
    setModel(_model);
    if(cameraControls.current && _model && !cameraBeenCentered) {
      setCameraBeenCentered(true)
      centerCamera()
    }
    if (_model !== null) {
      setLines(lines)
    }  
  }

  useEffect(() => {
    if(cameraControls.current && model) {
      centerCamera()
      window.addEventListener('keydown', gcodeShortcuts)
      return () => {
        window.removeEventListener('keydown', gcodeShortcuts)
      }
    }
  }, [cameraControls?.current, model])

  const handleLineClick = (layerIndex: number) => {
    setActiveLayer(layerIndex);
    document.getElementById('gcode-layer-' + layerIndex)?.scrollIntoView({behavior: 'smooth'})
    const selectionGcode: string | undefined = model?.layers[layerIndex].extruderPoints.reduce((acc: string, extruderPoint: ExtruderPoint) => {
      return acc + extruderPoint.originalString + '\n'
    }, '');
    if(selectionGcode) {
      setHighlightedGCode(selectionGcode);
    }
  };

  const handleCanvasClick = () => {
    setActiveLayer(-1);
    setHighlightedGCode('');
  };


  useEffect(() => {
    if (model) {
      // extrudedGeometry(model, setProgressGeometry, setShadowsEnabled).then(geometry => {
      //   geometry.computeVertexNormals()
      //   geometry.computeBoundsTree()
      //   setGeometry(geometry)
      // })
      const _geometry = createPrintGeometry(model, 1.75, nozzleDiameter ? nozzleDiameter : 0.8)
      _geometry.computeVertexNormals()
      _geometry.computeBoundsTree()
      setGeometry(_geometry)
    }
  }, [showModel, model])

  useEffect(() => {
    if(meshRef.current) {
      setMesh(meshRef.current)
    }
  }, [meshRef, meshRef.current])

  useEffect(() => {
    if (mesh) {
    }
  }, [mesh])
  
  const findPointUnderneath = (point: ExtruderPoint, previousLayerPoints: ExtruderPoint[], radius: number): ExtruderPoint | null => {
    for (const prevPoint of previousLayerPoints) {
      const distance = Math.sqrt(Math.pow(point.x - prevPoint.x, 2) + Math.pow(point.y - prevPoint.y, 2));
      if (distance <= radius) {
        return prevPoint;
      }
    }
    return null;
  };
  
  const calculateExtrusionDimensions = (prevPoint: ExtruderPoint, point: ExtruderPoint, filamentDiameter: number, nozzleDiameter: number, previousLayerPoints: ExtruderPoint[]): { width: number, height: number } => {
    const extrudedLength = point.e - prevPoint.e;
    const filamentRadius = filamentDiameter / 2;
    const extrusionVolume = extrudedLength * Math.PI * filamentRadius * filamentRadius;
  
    // Find the point underneath in the previous layer
    const pointUnderneath = findPointUnderneath(point, previousLayerPoints, nozzleDiameter * 1.5);
  
    // Calculate the layer height based on the point underneath
    const layerHeight = pointUnderneath ? point.z - pointUnderneath.z : nozzleDiameter;
  
    // Ensure layerHeight is not zero to avoid division by zero
    const effectiveLayerHeight = Math.max(layerHeight, 0.01);
  
    // Calculate the distance between the points
    const distance = Math.sqrt(Math.pow(point.x - prevPoint.x, 2) + Math.pow(point.y - prevPoint.y, 2) + Math.pow(point.z - prevPoint.z, 2));
  
    // Calculate the area of the road
    const areaOfRoad = (nozzleDiameter - effectiveLayerHeight) * effectiveLayerHeight + Math.PI * Math.pow(effectiveLayerHeight / 2, 2);
  
    // Calculate the width based on the extrusion volume, area of the road, and distance
    const width = extrusionVolume / (areaOfRoad * distance);
  
    // Cap the width to a reasonable range based on the nozzle diameter
    const cappedWidth = Math.min(Math.max(width, nozzleDiameter * 1.05), nozzleDiameter * 10.0);
  
    return { width: cappedWidth, height: effectiveLayerHeight };
  };


const createExtrusionSegment = (
    start: ExtruderPoint,
    end: ExtruderPoint,
    width: number,
    height: number,
    prev?: ExtruderPoint,
    next?: ExtruderPoint
): { positions: number[], normals: number[] } => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const dz = end.z - start.z;
    const length = Math.sqrt(dx * dx + dy * dy + dz * dz);

    // Normalize direction vector
    const dirX = dx / length;
    const dirY = dy / length;
    const dirZ = dz / length;

    // Calculate perpendicular vector in XY plane
    const perpX = -dirY;
    const perpY = dirX;

    // Calculate vertices
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    // Create a rectangular cross-section
    const positions: number[] = [];
    const normals: number[] = [];

    // Function to adjust vertex based on angle with segment
    const adjustVertex = (x: number, y: number, z: number, angleAdjust: number) => {
        const adjustedX = x + angleAdjust * dirX;
        const adjustedY = y + angleAdjust * dirY;
        const adjustedZ = z + angleAdjust * dirZ;
        return [adjustedX, adjustedY, adjustedZ];
    };

    // Calculate the angle adjustment if the previous and next points are provided
    let angleAdjustStart = 0;
    let angleAdjustEnd = 0;

    if (prev) {
        const prevDx = start.x - prev.x;
        const prevDy = start.y - prev.y;
        const prevDz = start.z - prev.z;
        const prevLength = Math.sqrt(prevDx * prevDx + prevDy * prevDy + prevDz * prevDz);

        const prevDirX = prevDx / prevLength;
        const prevDirY = prevDy / prevLength;
        const prevDirZ = prevDz / prevLength;

        const dotProduct = dirX * prevDirX + dirY * prevDirY + dirZ * prevDirZ;
        const angle = Math.acos(dotProduct);

        // Adjust based on the angle to the previous segment
        angleAdjustStart = Math.tan(angle / 2) * halfWidth;
    }

    if (next) {
        const nextDx = next.x - end.x;
        const nextDy = next.y - end.y;
        const nextDz = next.z - end.z;
        const nextLength = Math.sqrt(nextDx * nextDx + nextDy * nextDy + nextDz * nextDz);

        const nextDirX = nextDx / nextLength;
        const nextDirY = nextDy / nextLength;
        const nextDirZ = nextDz / nextLength;

        const dotProduct = dirX * nextDirX + dirY * nextDirY + dirZ * nextDirZ;
        const angle = Math.acos(dotProduct);

        // Adjust based on the angle to the next segment
        angleAdjustEnd = Math.tan(angle / 2) * halfWidth;
    }

    // Adjust vertices based on both start and end angles
    const startAdjust = angleAdjustStart;
    const endAdjust = angleAdjustEnd;

    // Start point vertices
    let adjusted = adjustVertex(start.x - perpX * halfWidth, start.y - perpY * halfWidth, start.z - halfHeight, -startAdjust);
    positions.push(...adjusted);
    adjusted = adjustVertex(start.x + perpX * halfWidth, start.y + perpY * halfWidth, start.z - halfHeight, -startAdjust);
    positions.push(...adjusted);
    adjusted = adjustVertex(start.x + perpX * halfWidth, start.y + perpY * halfWidth, start.z + halfHeight, -startAdjust);
    positions.push(...adjusted);
    adjusted = adjustVertex(start.x - perpX * halfWidth, start.y - perpY * halfWidth, start.z + halfHeight, -startAdjust);
    positions.push(...adjusted);

    // End point vertices
    adjusted = adjustVertex(end.x - perpX * halfWidth, end.y - perpY * halfWidth, end.z - halfHeight, endAdjust);
    positions.push(...adjusted);
    adjusted = adjustVertex(end.x + perpX * halfWidth, end.y + perpY * halfWidth, end.z - halfHeight, endAdjust);
    positions.push(...adjusted);
    adjusted = adjustVertex(end.x + perpX * halfWidth, end.y + perpY * halfWidth, end.z + halfHeight, endAdjust);
    positions.push(...adjusted);
    adjusted = adjustVertex(end.x - perpX * halfWidth, end.y - perpY * halfWidth, end.z + halfHeight, endAdjust);
    positions.push(...adjusted);

    // Calculate averaged normals
    const calculateNormal = (x: number, y: number, z: number) => {
        const length = Math.sqrt(x * x + y * y + z * z);
        return [x / length, y / length, z / length];
    };

    const normal1 = calculateNormal(-perpX, -perpY, 0);
    const normal2 = calculateNormal(perpX, perpY, 0);

    // Start point normals (averaged with previous if available)
    const startNormals = prev ? [
        calculateNormal(normal1[0] + dirX, normal1[1] + dirY, normal1[2] + dirZ),
        calculateNormal(normal2[0] + dirX, normal2[1] + dirY, normal2[2] + dirZ)
    ] : [normal1, normal2];

    for (let i = 0; i < 4; i++) {
        normals.push(...startNormals[0]);
        normals.push(...startNormals[1]);
        normals.push(...startNormals[1]);
        normals.push(...startNormals[0]);
    }

    // End point normals (averaged with next if available)
    const endNormals = next ? [
        calculateNormal(normal1[0] + dirX, normal1[1] + dirY, normal1[2] + dirZ),
        calculateNormal(normal2[0] + dirX, normal2[1] + dirY, normal2[2] + dirZ)
    ] : [normal1, normal2];

    for (let i = 0; i < 4; i++) {
        normals.push(...endNormals[0]);
        normals.push(...endNormals[1]);
        normals.push(...endNormals[1]);
        normals.push(...endNormals[0]);
    }

    return { positions, normals };
};



    
  // WORKING
  // const createExtrusionSegment = (
  //   start: ExtruderPoint, 
  //   end: ExtruderPoint, 
  //   width: number, 
  //   height: number, 
  //   prev?: ExtruderPoint, 
  //   next?: ExtruderPoint
  // ): { positions: number[], normals: number[] } => {
  //   const dx = end.x - start.x;
  //   const dy = end.y - start.y;
  //   const dz = end.z - start.z;
  //   const length = Math.sqrt(dx * dx + dy * dy + dz * dz);
  
  //   // Normalize direction vector
  //   const dirX = dx / length;
  //   const dirY = dy / length;
  //   const dirZ = dz / length;
  
  //   // Calculate perpendicular vector in XY plane
  //   const perpX = -dirY;
  //   const perpY = dirX;
  
  //   // Calculate vertices
  //   const halfWidth = width / 2;
  //   const halfHeight = height / 2;
  
  //   // Create a rectangular cross-section
  //   const positions: number[] = [];
  //   const normals: number[] = [];
  
  //   // Function to adjust vertex based on angle with next segment
  //   const adjustVertex = (x: number, y: number, z: number, angleAdjust: number) => {
  //     const adjustedX = x + angleAdjust * dirX;
  //     const adjustedY = y + angleAdjust * dirY;
  //     const adjustedZ = z + angleAdjust * dirZ;
  //     return [adjustedX, adjustedY, adjustedZ];
  //   };
  
  //   // Calculate the angle adjustment if the next point is provided
  //   let angleAdjustStart = 0;
  //   let angleAdjustEnd = 0;
  
  //   if (prev) {
  //     const prevDx = start.x - prev.x;
  //     const prevDy = start.y - prev.y;
  //     const prevDz = start.z - prev.z;
  //     const prevLength = Math.sqrt(prevDx * prevDx + prevDy * prevDy + prevDz * prevDz);
  
  //     const prevDirX = prevDx / prevLength;
  //     const prevDirY = prevDy / prevLength;
  //     const prevDirZ = prevDz / prevLength;
  
  //     const dotProduct = dirX * prevDirX + dirY * prevDirY + dirZ * prevDirZ;
  //     const angle = Math.acos(dotProduct);
  
  //     // Adjust based on the angle to the previous segment
  //     angleAdjustStart = Math.tan(angle / 2) * halfWidth;
  //   }
  
  //   if (next) {
  //     const nextDx = next.x - end.x;
  //     const nextDy = next.y - end.y;
  //     const nextDz = next.z - end.z;
  //     const nextLength = Math.sqrt(nextDx * nextDx + nextDy * nextDy + nextDz * nextDz);
  
  //     const nextDirX = nextDx / nextLength;
  //     const nextDirY = nextDy / nextLength;
  //     const nextDirZ = nextDz / nextLength;
  
  //     const dotProduct = dirX * nextDirX + dirY * nextDirY + dirZ * nextDirZ;
  //     const angle = Math.acos(dotProduct);
  
  //     // Adjust based on the angle to the next segment
  //     angleAdjustEnd = Math.tan(angle / 2) * halfWidth;
  //   }
  
  //   // Start point vertices
  //   positions.push(start.x - perpX * halfWidth, start.y - perpY * halfWidth, start.z - halfHeight);
  //   positions.push(start.x + perpX * halfWidth, start.y + perpY * halfWidth, start.z - halfHeight);
  //   positions.push(start.x + perpX * halfWidth, start.y + perpY * halfWidth, start.z + halfHeight);
  //   positions.push(start.x - perpX * halfWidth, start.y - perpY * halfWidth, start.z + halfHeight);
  
  //   // End point vertices
  //   let adjusted = adjustVertex(end.x - perpX * halfWidth, end.y - perpY * halfWidth, end.z - halfHeight, angleAdjustEnd);
  //   positions.push(...adjusted);
  //   adjusted = adjustVertex(end.x + perpX * halfWidth, end.y + perpY * halfWidth, end.z - halfHeight, angleAdjustEnd);
  //   positions.push(...adjusted);
  //   adjusted = adjustVertex(end.x + perpX * halfWidth, end.y + perpY * halfWidth, end.z + halfHeight, angleAdjustEnd);
  //   positions.push(...adjusted);
  //   adjusted = adjustVertex(end.x - perpX * halfWidth, end.y - perpY * halfWidth, end.z + halfHeight, angleAdjustEnd);
  //   positions.push(...adjusted);
  
  //   // Normals (pointing outward from the center of the tube)
  //   for (let i = 0; i < 4; i++) {
  //     normals.push(-perpX, -perpY, 0);
  //     normals.push(perpX, perpY, 0);
  //     normals.push(perpX, perpY, 0);
  //     normals.push(-perpX, -perpY, 0);
  //   }
  
  //   return { positions, normals };
  // };
  
  
  
  
  const createPrintGeometry = (model: Model, filamentDiameter: number, nozzleDiameter: number): THREE.BufferGeometry => {
    const positions: number[] = [];
    const normals: number[] = [];
    const indices: number[] = [];
  
    let prevPoint: ExtruderPoint | null = null;
    let vertexIndex = 0;
  
    model.layers.forEach((layer: Layer, layerIndex: number) => {
      if (layer.extruderPoints.length === 0) return;
  
      const previousLayerPoints = layerIndex > 0 ? model.layers[layerIndex - 1].extruderPoints : [];
  
      layer.extruderPoints.forEach((point: ExtruderPoint, pointIndex: number) => {
        const isExtruding = model.relativeExtrusion ?
          point.e > 0 ? true : false
          : prevPoint ?
            point.e > prevPoint.e ? true : false
            : point.e > 0 ? true : false
  
        if (prevPoint && isExtruding) {  // Check if the current E value is greater than the previous E value
          const { width, height } = calculateExtrusionDimensions(prevPoint, point, filamentDiameter, nozzleDiameter, previousLayerPoints);
  
          const prev: ExtruderPoint | undefined = pointIndex > 2 ? layer.extruderPoints[pointIndex - 2] : undefined
          const next: ExtruderPoint | undefined = pointIndex < layer.extruderPoints.length - 1 ? layer.extruderPoints[pointIndex + 1] : undefined
          const vertices = createExtrusionSegment(prevPoint, point, width, height, prev, next);
  
          positions.push(...vertices.positions);
          normals.push(...vertices.normals);
  
          const segmentVertexCount = vertices.positions.length / 3;
          for (let i = 0; i < segmentVertexCount - 4; i += 4) {
            indices.push(
              vertexIndex + i, vertexIndex + i + 1, vertexIndex + i + 4,
              vertexIndex + i + 1, vertexIndex + i + 5, vertexIndex + i + 4,
              vertexIndex + i + 1, vertexIndex + i + 2, vertexIndex + i + 5,
              vertexIndex + i + 2, vertexIndex + i + 6, vertexIndex + i + 5,
              vertexIndex + i + 2, vertexIndex + i + 3, vertexIndex + i + 6,
              vertexIndex + i + 3, vertexIndex + i + 7, vertexIndex + i + 6,
              vertexIndex + i + 3, vertexIndex + i, vertexIndex + i + 7,
              vertexIndex + i, vertexIndex + i + 4, vertexIndex + i + 7
            );
          }
          vertexIndex += segmentVertexCount;
        }
        prevPoint = point;
      });
    });
  
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
    geometry.setIndex(indices);
    // const _geometry = BufferGeometryUtils.mergeVertices(geometry, 0.1)
    // geometry.computeVertexNormals()
    return geometry;
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' , flexDirection: 'column', alignItems: 'center'}}>
      {!model && 
        <div className='absolute w-64 h-64 flex items-center justify-center m-auto left-0 right-0 top-0 bottom-0'>
          <Spin className="inline-spin h-4 w-4" color="#ffffff" width="20px" height="20px" duration="0.3s" />
        </div>
      }
      <div className={`absolute w-64 justify-between flex text-bw-green pulse flex-col items-center h-16 m-auto left-0 right-0 top-0 bottom-0 p-4 bg-bw-background-grey shadow-2xl rounded-sm z-50 text-xs ${progressGeometry === 0 || progressGeometry === 100 ? 'hidden' : ''}`}>
        <div className='animate-pulse'>Building the print preview ...</div>
        <ProgressBar className='w-full' height={6} progress={progressGeometry} bright/>
      </div>
      <div className={`absolute m-auto left-0 right-0 bottom-16 z-50 text-sm w-64 flex flex-col gap-2 items-center bg-bw-background-grey rounded-md p-4 shadow-2xl ${(activeLayer !== -1 && !showModel) ? '' : 'hidden'}`}>
        Edit layer: {activeLayer}
          <Button variant='bwsecondary' className='w-full' disabled>Currently unavailable</Button>
      </div>
      <div className='absolute bottom-8 left-8 text-xs z-50 flex flex-col gap-2'>
        <div className='flex flex-col p-4 rounded-xl bg-white text-nowrap gap-3'>
          <div className='flex justify-between gap-1 font-bold items-center'>
            Print Preview (p)
            <Switch checked={showModel ? true : false} onCheckedChange={(checked) => setShowModel(checked)} tabIndex={10} title='Show extruded model'/>
          </div>
          <div className='flex gap-1 justify-between pl-2 items-center mb-0'>
            Filament matt
            <Switch checked={gcodeFilamentMatt ? true : false} onCheckedChange={(checked) => setGcodeFilamentMatt(checked)} tabIndex={10} title='Show extruded model'/>
          </div>
          <div className='flex justify-between gap-1 pl-2 items-center'>
            Filament colour
            <Popover>
              <PopoverTrigger className='relative' asChild >
                <Button variant="link" className='relative h-auto' size="icon">
                  <div className={`w-4 h-4 ml-3 border border-bw-green/10 rounded-xl`} style={{backgroundColor: gcodeFilamentColor}}></div>
                </Button>
              </PopoverTrigger>
                <PopoverContent align='start'>
                    <CirclePicker 
                      colors={['#FFFEF3', '#020407', '#31ADE2', '#46C6B4', '#FFCD59', 
                        '#FF5E63', '#D7942E', '#AA5042', '#ECE7D7', '#B8CDB6', 
                        '#DFBAA9', '#003272', '#A0A0A0', '#545151']}
                      circleSize={20}
                      onChange={(color) => filamentColourChanged(color)}/>
                </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className='flex flex-col p-4 rounded-xl bg-white text-nowrap'>
          <div className='flex gap-1 font-bold items-center justify-between'>
            Floor Grid (g)
            <Switch checked={showGrid ? true : false} onCheckedChange={(checked) => setShowGrid(checked)} tabIndex={10} title='Toggle floor grid'/>
          </div>
        </div>
        <div className='flex flex-col p-4 rounded-xl bg-white'>
          <div>{gcodeFile?.fileName}</div>
          <div>Nozzle Size: {gcodeFile?.fileattributesmodelSet ? gcodeFile?.fileattributesmodelSet[0]?.nozzleSize + ' mm' : 'n/a'}</div>
          <div>Print Time: {gcodeFile?.fileattributesmodelSet ? gcodeFile?.fileattributesmodelSet[0]?.printTime + ' mins' : 'n/a'}</div>
          <div>Print Weight: {gcodeFile?.fileattributesmodelSet ? gcodeFile?.fileattributesmodelSet[0]?.printWeight + ' g' : 'n/a' }</div>
          <div>W {model ? (model.modelBounds.x.max - model.modelBounds.x.min) : 'undefined'} mm</div>
          <div>H {model ? (model.modelBounds.z.max - model.modelBounds.z.min) : 'undefined'} mm</div>
          <div>D {model ? (model.modelBounds.y.max - model.modelBounds.y.min) : 'undefined'} mm</div>
          <div>Total layers: {model?.layers.length}</div>
        </div>
      </div>
      <Canvas shadows={{ type: THREE.PCFSoftShadowMap, enabled: shadowsEnabled}} gl={{toneMapping: THREE.ACESFilmicToneMapping}} frameloop='demand' dpr={1} onPointerMissed={handleCanvasClick} camera={{up: [0, 0, 1]}}>
        <ambientLight intensity={0.23} />
        <spotLight intensity={1.3} castShadow position={new THREE.Vector3(-100, 200, 1200)} distance={2000} decay={1} color={0xffeeee} />
        <spotLight intensity={1} castShadow position={new THREE.Vector3(500, 500, 1800)} distance={2000} decay={1} color={0xffffff} />
        <Environment preset={'warehouse'} blur={0.8} />
        <Floor grid={showGrid} size={floorSize} />
        {showModel ?
          geometry ?
            <mesh castShadow receiveShadow geometry={geometry} ref={meshRef} material={phongShader}/>
            : null
          :
          modelToThreeLines(model).filter(line => line.length > 1).map((line, i) => {
            return (
              <Line
                key={i}
                ref={lineModel}
                points={line}
                color={i === activeLayer ? "red": (i === hoveredLine ? "blue":"#333333")}
                lineWidth={i === activeLayer ? 3 : 1.5}
                onClick={() => handleLineClick(i)}
                onPointerOver={() => setHoveredLine(i)}
                onPointerOut={() => setHoveredLine(-1)}
              />
            )})
        }
        {(model && geometry) && <BakeShadows />}
        <CameraControls ref={cameraControls} />
        {cameraControls ? 
          <SetOrbit cameraControls={cameraControls} mesh={mesh} />
          : null
        }
      </Canvas>
    </div>
  );
}

export default GCodeViewer;
